import React from 'react'
import EditIcon from '@mui/icons-material/Edit';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestoreIcon from '@mui/icons-material/Restore';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import { Box, styled, Typography } from '@mui/material'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FadeLoader from "react-spinners/FadeLoader";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogTitle from '@mui/material/DialogTitle';




const MainBox = styled(Box)(({theme})=>({
    display : 'flex',
    background: '#F3F7F8',
    [theme.breakpoints.down('lg')]:{
        display : 'flex',
        background: '#F3F7F8',
    },
    [theme.breakpoints.down('sm')]:{
        display:'block',
        marginTop:'-55px',
        height: '100%',
    }
}))

const ProfileBox = styled(Box)(({theme})=>({
    width: '325px',
    height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '165px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'35px',
       
    }
}))


const ImageBox = styled(Box)(({theme})=>({
    marginLeft: '105px',
    marginTop: '50px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '75px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'105px',
        paddingTop:'30px',
    }
}))

const NameText = styled(Typography)`
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const NumberText = styled(Typography)`
    text-align: center;
    margin-top: 3px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const MainLogoutBox = styled(Box)`
  display: flex;
`

const LogotText = styled(Typography)`
  margin-left: 5px;
  margin-top: 14px;
  color: #F15A24;
`
const MainLogoutBoxSec = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]: {

    }
}))

const IconBox = styled(Box)(({theme})=> ({
    color: '#F15A24', 
    marginTop: '15px',
    marginLeft: '120px', 
   [theme.breakpoints.down('lg')]:{
     marginLeft: '125px', 
   },
   [theme.breakpoints.down('sm')]:{
        marginLeft:'120px'
   }
}))

const IconBoxSec = styled(Box)(({theme})=>({
    color: '#F15A24', 
    marginTop: '2px', 
    marginLeft: '75px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '93px',
        marginTop: '0px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'95px'
    }
}))




const LogotTextSec = styled(Typography)(({ theme }) => ({
    marginLeft: '25px',
    marginTop: '2px',
    color: '#F15A24',
    [theme.breakpoints.down('lg')]: {
        marginTop: '0px',
    }
}))


const ProfileBoxSec = styled(Box)(({theme})=>({
    width: '825px',
    //height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '53px',
    [theme.breakpoints.down('lg')]:{
        width: '565px',
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '325px',
        marginLeft: '35px',
        height: '350px',
    }
}))

// const VehicleMode = styled(Typography)`
//     text-align: center;
//     margin-top: 50px;
//     color: black;
//     font-size: 16px;
//     font-weight: 600;
// `

// const VehicleBox = styled(Box)`
//     display:flex;

//     margin-top: 30px;

// `
const BoxVehicle = styled(Box)(({ theme }) => ({
    width: '95%',
    height: '140px',
    background: '#F3F7F8',
    boxShadow: "0 2px 4px 0 rgb(0 0 0 / 20%)",
    borderRadius: '10px',
    margin: '21px',
    [theme.breakpoints.down('lg')]: {
        margin: '15px',
    },
    [theme.breakpoints.down('sm')]:{
        height:'180px',
        margin: '8px',
        marginTop:'20px',
    }
}))



// const ImageIcon = styled('img')({
//     width: '40px',
//     marginLeft: '25px',
//     marginTop: '15px',
// })

const TextShip = styled(Typography)`
    margin-left: 20px;
    padding: 10px;
    font-size: 16px;
`

const FlatTirBooking = styled(Typography)(({theme})=>({
    marginTop: '30px',
    textAlign: 'center',
    fontSize: '18px',
    color: '#F15A24',
    fontFamily: "'Lato', sans-serif",
    [theme.breakpoints.down('lg')]:{
        marginTop: '30px',
        textAlign: 'center',
        fontSize: '18px',
        color: '#F15A24',
        fontFamily: "'Lato', sans-serif",
    },
    [theme.breakpoints.down('sm')]:{
        paddingTop:'35px',
    }
}))



const NextBox = styled(Box)(({ theme }) => ({
    width: '90px',
    height: '30px',
    background: '#F15A24',
    color: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '373px',
    marginTop: '10px',
    marginBottom:'25px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '235px',
        marginTop: '10px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'120px',
        marginTop:'20px'
    }
}))



const NextType = styled(Typography)`
    padding: 3px;
    margin-left: 28px;
`

const PopupBox = styled(Box)`
    width: 150px;
    height: 25px;
    background: #F15A24;
    color: white;
    margin: 12px;
    border-radius: 4px;
    margin-left: 0px;
 
`
const PopupText = styled(Typography)`
    padding: 2px;
    fontFamily: 'Lato', sans-serif;
    font-size: 13px;
    text-align:center;
`

// const DropBox = styled(Box)`
//     width: 250px;
// `

const SameBox = styled(Box)`
    margin-top: -30px;
    margin-left: 16px;
`

const LoaderBox = styled(Box)(({theme})=>({
    marginLeft: '390px',
    marginTop: '60px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '255px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft: '140px',
    }
}))

const NameTextTh = styled(Typography)`
    text-align: center;
    margin: 4px;
    margin-top: -5px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`


const FlatTire = () => {
    const [open, setOpen] = React.useState(false);
    const search = window.location.search;
    const params = new URLSearchParams(search)
    let vtype = params.get("vtype");
    let servtype = params.get("servtype");
    let subsurvice = params.get("subsurvice");
    let subservname = params.get("subservname");
    let image = params.get("image");
    let price = params.get("price");
    const userid = localStorage.getItem('userid');
    const [storeImage, setStoreImage] = useState([])

    const [loading, setLoading] = useState(false)
    const [FlatTire, setFlatTire] = useState([]);
    const FlatQuestion = async () => {
        const response = await fetch(`https://demo.towbooker.com/api/get-condition-question?id=${subsurvice}`)
        const data = await response.json();
        setFlatTire(data.data)
    }
    useEffect(() => {
        FlatQuestion();
    }, [])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1500)
    }, [])


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [userName, setUserName] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        profile_photo: ''
    })

    const ProfileImageShow = storeImage.map(item => item.imgcro)

    const userSetData = async () => {
        const response = await fetch(`https://demo.towbooker.com/api/viewUserbyid?userid=${userid}`)
        const data = await response.json();
        //console.log(data);

        setUserName(data.data)
        // localStorage.setItem('username', res.data.message);
        // console.log(data.data) 

    }

    useEffect(() => {
        userSetData();
    }, [])



    return (
        <>
            <MainBox>
                <ProfileBox>
                    <ImageBox>
                        <div className="flex-shrink-0">
                            <img src={ProfileImageShow}
                                alt="Generic placeholder image" className="img-fluid"
                                style={{ width: '110px', borderRadius: '50%' }} />
                        </div>
                    </ImageBox>

                    <NameText>{userName.first_name}</NameText>
                    <NameTextTh>{userName.last_name}</NameTextTh>

                    <NumberText>{userName.mobile_number}</NumberText>
                    <NumberText>{userName.email}</NumberText>
                    <MainLogoutBox>
                        <IconBox>
                            <EditIcon style={{ fontSize: '22px' }} />
                        </IconBox>
                        <LogotText>Edit</LogotText>
                    </MainLogoutBox>
                    <hr />
                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <AccountCircleIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>

                        <LogotTextSec>Profile</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <AccessTimeIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>

                        <Link to='/booking' style={{ textDecoration: 'none' }} >
                            <LogotTextSec>Bookings</LogotTextSec>
                        </Link>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <PaymentIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>

                        <LogotTextSec>Payments</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <RestoreIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>

                        <LogotTextSec>Transactions</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <StarBorderPurple500Icon style={{ fontSize: '22px' }} />
                        </IconBoxSec>

                        <LogotTextSec>Review</LogotTextSec>
                    </MainLogoutBoxSec>

                </ProfileBox>

                <ProfileBoxSec>
                    <FlatTirBooking>{subservname}</FlatTirBooking>
                    {
                        loading ?
                            <LoaderBox>
                                <FadeLoader
                                    color={'#F18180'}
                                    loading={loading}

                                    size={80}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </LoaderBox> :
                            <Box>
                                {
                                    FlatTire.map((TireFlat, index) => {
                                        return (
                                            <div className='bootstrap-wrapper mt-1' key={index}>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <BoxVehicle>
                                                            <TextShip>{TireFlat.question}</TextShip>
                                                            <div className='container-fluid '>
                                                                <div className='row m-2'>
                                                                    <div className='col-md-6'>
                                                                        <Typography>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                                                                <label className="form-check-label" for="inlineRadio1">{TireFlat.answer1}</label>
                                                                            </div>
                                                                        </Typography>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <Typography>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                                                                <label className="form-check-label" for="inlineRadio1">{TireFlat.answer2}</label>
                                                                            </div>
                                                                        </Typography>
                                                                    </div>

                                                                </div>

                                                                <div className='row m-2'>
                                                                    <div className='col-md-6'>
                                                                        <Typography>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3" />
                                                                                <label className="form-check-label" for="inlineRadio1">{TireFlat.answer3}</label>
                                                                            </div>
                                                                        </Typography>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <Typography>
                                                                            <div className="form-check form-check-inline">
                                                                                <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4" />
                                                                                <label className="form-check-label" for="inlineRadio1">{TireFlat.answer4}</label>
                                                                            </div>
                                                                        </Typography>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </BoxVehicle>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <NextBox variant="outlined" onClick={handleClickOpen}>
                                    <NextType>Next</NextType>
                                </NextBox>
                            </Box>
                    }


                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            <Typography style={{ color: 'grey', fontSize: '13px', textAlign: 'center' }}>What You wont't to do</Typography>
                            <hr />
                        </DialogTitle>
                        {/* <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                

                            </DialogContentText>
                        </DialogContent> */}
                        <DialogActions>
                            <SameBox>
                                <PopupBox>
                                    <Link to={`/sedulebooking?vtype=${vtype}&&servtype=${servtype}&&subsurvice=${subsurvice}&&subservname=${subservname}&&image=${image}&&price=${price}`} style={{ color: 'white', textDecoration: 'none' }} onClick={handleClose}>
                                        <PopupText>Schedule</PopupText>
                                    </Link>
                                </PopupBox>

                                <PopupBox>
                                    <Link to={`/vehicleinfo?vtype=${vtype}&&servtype=${servtype}&&subsurvice=${subsurvice}&&subservname=${subservname}&&image=${image}&&price=${price}`} style={{ color: 'white', textDecoration: 'none' }} onClick={handleClose} autoFocus>
                                        <PopupText>Urgent</PopupText>
                                    </Link>
                                </PopupBox>

                            </SameBox>

                        </DialogActions>
                    </Dialog>
                </ProfileBoxSec>


            </MainBox>
        </>
    )
}

export default FlatTire
