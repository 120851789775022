import React, { useState, useEffect } from 'react';
import FadeLoader from "react-spinners/FadeLoader";
import EditIcon from '@mui/icons-material/Edit';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestoreIcon from '@mui/icons-material/Restore';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import { Box, styled, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';



const MainBox = styled(Box)(({theme})=>({
    display : 'flex',
    background: '#F3F7F8',
    [theme.breakpoints.down('lg')]:{
        display : 'flex',
        background: '#F3F7F8',
    },
    [theme.breakpoints.down('sm')]:{
        display:'block',
        marginTop:'-55px',
        height: '100%',
    }
}))

const ProfileBox = styled(Box)(({theme})=>({
    width: '325px',
    height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '165px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'35px',
       
    }
}))


const ImageBox = styled(Box)(({theme})=>({
    marginLeft: '105px',
    marginTop: '50px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '75px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'105px',
        paddingTop:'30px',
    }
}))

const NameText = styled(Typography)`
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const NameTextTh = styled(Typography)`
    text-align: center;
    margin: 4px;
    margin-top: -5px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const NumberText = styled(Typography)`
    text-align: center;
    margin-top: 3px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const MainLogoutBox = styled(Box)`
  display: flex;
`

const LogotText = styled(Typography)`
  margin-left: 5px;
  margin-top: 14px;
  color: #F15A24;
`


const MainLogoutBoxSec = styled(Box)(({theme})=>({
    display: 'flex',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]:{
        
    }
}))

const IconBox = styled(Box)(({theme})=> ({
    color: '#F15A24', 
    marginTop: '15px',
    marginLeft: '120px', 
   [theme.breakpoints.down('lg')]:{
     marginLeft: '93px', 
   },
   [theme.breakpoints.down('sm')]:{
        marginLeft:'120px'
   }
}))


const IconBoxSec = styled(Box)(({theme})=>({
    color: '#F15A24', 
    marginTop: '2px', 
    marginLeft: '75px',
    [theme.breakpoints.down('lg')]:{
        marginLeft:'63px',
        marginTop: '0px', 
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'95px'
    }
}))


const LogotTextSec = styled(Typography)(({theme})=>({
    marginLeft: '25px',
    marginTop: '2px',
    color: '#F15A24',
    [theme.breakpoints.down('lg')]:{
        marginTop: '0px',
    }
}))

const ProfileBoxSec = styled(Box)(({theme})=>({
    width: '825px',
    height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '53px',
    [theme.breakpoints.down('lg')]:{
        width: '565px',
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '325px',
        marginLeft: '35px',
        height: '550px',
    }
}))

const VehicleMode = styled(Typography)(({theme})=>({
    textAlign: 'center',
    marginTop: '50px',
    color: '#F15A24',
    fontSize: '16px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]:{
        textAlign: 'center',
        marginTop: '50px',
        color: '#F15A24',
        fontSize: '16px',
        fontWeight: '600',
    },
    [theme.breakpoints.down('sm')]:{
        paddingTop:'45px'
    }
}))



const VehicleBox = styled(Box)(({theme})=> ({
    display:'flex',
    marginTop: '30px',
    [theme.breakpoints.down('lg')]:{
        overflow:'scroll'
    },
    [theme.breakpoints.down('sm')]:{
        overflow:'scroll'
    }
}))



const BoxVehicle = styled(Box)`
    width: 90px;
    height: 90px;
    background: #F3F7F8;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    border-radius: 10px;
    margin: 15px;
    cursor: pointer;
`

const BoxVehiclee = styled(Box)`
    width: 90px;
    height: 90px;
    border: 2px solid #F15A24;
  
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    border-radius: 10px;
    margin: 15px;
    cursor: pointer;
`

const ImageIcon = styled('img')({
    width: '40px',
    marginLeft: '25px',
    marginTop: '15px',
})

const TextShip = styled(Typography)`
    text-align: center;
    font-size: 13px;
`

const LoaderBox = styled(Box)(({theme})=>({
    marginLeft: '390px',
    marginTop: '60px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '255px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft: '140px',
    }
}))


const Booking = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [ArrayVehicle, setArrayVehicleLocal] = useState([]);
    const [Service, setService] = useState([]);
    const [vehicletype, setvehicletype] = useState();
    const [servicetype, setservicetype] = useState();
    const userid =  localStorage.getItem('userid');
    const [storeImage, setStoreImage] = useState([])
  


    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1500)
    }, [])


    const getVhicle = async () => {
        const response = await fetch('https://demo.towbooker.com/api/get-vehicle')
        const data = await response.json();
        setArrayVehicleLocal(data.data)
        // console.log(data.data) 
    }


    const setvehiclee = (id) => {
        setvehicletype(id);
    }

    const setservicee = (id, name) => {
        setservicetype(id)
        navigate(`/flattireoption?vtype=${vehicletype}&&servtype=${id}&&subservname=${name}`)
    }

    useEffect(() => {
        getVhicle();
    }, [])

    const getService = async () => {
        const response = await fetch('https://demo.towbooker.com/api/get-service')
        const data = await response.json();
        setService(data.data)
    }
    useEffect(() => {
        getService();
    }, [])

    const [userName, setUserName] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        profile_photo:''
    })

    const ProfileImageShow = storeImage.map(item => item.imgcro)
       
    const userSetData = async () => {
       const response = await fetch(`https://demo.towbooker.com/api/viewUserbyid?userid=${userid}`)
        const data = await response.json();
        //console.log(data);
       
       setUserName(data.data)
       // localStorage.setItem('username', res.data.message);
        // console.log(data.data) 
         
    }

    useEffect(() => {
        userSetData();
    }, [])

    

    return (
        <>
            <MainBox>
            <ProfileBox>
                    <ImageBox>
                        <div className="flex-shrink-0">
                            <img src={ProfileImageShow}
                                alt="Generic placeholder image" className="img-fluid"
                                style={{ width: '110px', borderRadius: '50%' }} />
                        </div>
                    </ImageBox>
            
                        <NameText>{userName.first_name}</NameText>
                        <NameTextTh>{userName.last_name}</NameTextTh>
                   
                    <NumberText>{userName.mobile_number}</NumberText>
                    <NumberText>{userName.email}</NumberText>
                    <MainLogoutBox>
                        <IconBox>
                        <EditIcon style={{fontSize: '22px' }} />
                        </IconBox>
 
                        <LogotText>Edit</LogotText>
                    </MainLogoutBox>
                    <hr />
                    <MainLogoutBoxSec>
                        <IconBoxSec>
                        <AccountCircleIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>

                        <LogotTextSec>Profile</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>

                        <IconBoxSec>
                        <AccessTimeIcon style={{fontSize: '22px' }} />
                        </IconBoxSec>
                   
                        <Link to='/booking' style={{ textDecoration: 'none' }} >
                            <LogotTextSec>Bookings</LogotTextSec>
                        </Link>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                        <PaymentIcon style={{fontSize: '22px' }} />
                        </IconBoxSec>
             
                        <LogotTextSec>Payments</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                        <RestoreIcon style={{fontSize: '22px' }} />
                        </IconBoxSec>
                   
                        <LogotTextSec>Transactions</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                        <StarBorderPurple500Icon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
                      
                        <LogotTextSec>Review</LogotTextSec>
                    </MainLogoutBoxSec>

                </ProfileBox>

                <ProfileBoxSec>
                    <VehicleMode>Vehicle Type</VehicleMode>
                    <Box>
                        {
                            loading ?
                                <LoaderBox>
                                    <FadeLoader
                                        color={'#F18180'}
                                        loading={loading}

                                        size={80}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </LoaderBox>
                                :
                                <VehicleBox >
                                    {
                                        ArrayVehicle.map((elmentVehicle, index) => {
                                            let vehimg = "https://demo.towbooker.com/public/admin/vehicle/" + elmentVehicle.vihele_image;
                                            return (
                                                <Box onClick={() => { setvehiclee(elmentVehicle.id) }} key={index}>
                                                    {vehicletype == elmentVehicle.id ? (<BoxVehiclee>
                                                        <ImageIcon src={vehimg} alt="" />
                                                        <TextShip>{elmentVehicle.titlle}</TextShip>
                                                    </BoxVehiclee>) : (<BoxVehicle>
                                                        <ImageIcon src={vehimg} alt="" />
                                                        <TextShip>{elmentVehicle.titlle}</TextShip>
                                                    </BoxVehicle>)}
                                                </Box>
                                            )
                                        })
                                    }
                                </VehicleBox>
                        }


                    </Box>

                    <VehicleMode>Services</VehicleMode>
                    <Box>
                        {
                            loading ?
                                <LoaderBox>
                                    <FadeLoader

                                        color={'#F18180'}
                                        loading={loading}

                                        size={80}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </LoaderBox>
                                :
                                <VehicleBox>
                                    {
                                        Service.map((serviceVe, data) => {
                                            let imageset = `https://demo.towbooker.com/public/images/` +
                                                serviceVe.image;
                                            return (
                                                <Box onClick={() => { setservicee(serviceVe.id) }} key={data}>
                                                    {servicetype == serviceVe.id ? (<BoxVehiclee >
                                                        <ImageIcon src={imageset} alt="" />
                                                        <TextShip>{serviceVe.title}</TextShip>
                                                    </BoxVehiclee>) : (<BoxVehicle >
                                                        <ImageIcon src={imageset} alt="" />
                                                        <TextShip>{serviceVe.title}</TextShip>
                                                    </BoxVehicle>)}
                                                </Box>
                                            )
                                        })
                                    }
                                </VehicleBox>
                        }

                    </Box>
                </ProfileBoxSec>
            </MainBox>
        </>
    )
}

export default Booking
