import React from 'react'
import House from '../Header/House'
import { Box, styled } from '@mui/material'
import FlatSecond from './FlatSecond';


const MainBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '600px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]:{
        display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '-10px',
        display: 'block',
        height: '830px',
        overflow: 'hidden',
    }
}))

const TextBox = styled(Box)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    fontSize: '25px',
    wordWrap: 'break-word',
    marginTop: '90px',
    marginLeft: '170px',
    letterSpacing: '2px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]:{
        fontSize: '25px',
        marginTop: '95px',
        marginLeft: '100px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        wordWrap: 'break-word',
        padding: '25px',
        marginTop: '10px',
        marginLeft: '40px',
        letterSpacing: '2px',
        fontWeight: '400',
    }
}))


const TextBoxGet = styled(Box)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    width: '60%',
    fontSize: '16px',
    marginTop: '10px',
    wordWrap: 'break-word',
    marginLeft: '170px',
    letterSpacing: '1px',
    lineHeight:'22px',
    fontWeight: '500',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]:{
        width: '80%',
        fontSize: '16px',
        marginLeft: '100px',
        lineHeight:'20px', 
    },
    [theme.breakpoints.down('sm')]: {
        lineHeight:'15px',  
        fontSize: '10px',
        marginTop: '-17px',
        wordWrap: 'break-word',
        marginLeft: '20px',
        width: '90%',
        letterSpacing: '1px',
        fontWeight: '400',
        textAlign: 'center',
    }
}))


const KartBox = styled(Box)(({ theme }) => ({
    width: '240px',
    height: '70px',
    background: '#F1F3F6',
    marginLeft: '170px',
    marginTop: '45px',
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '100px',
        marginTop: '30px',
      },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '75px',
        marginTop: '15px',
    }

}))


const TypeTextBox = styled(Box)`
    color: #F15A24;
    margin-left: 17px;
    width: 100%;
    padding: 22px;
    font-size: 16px;
  `;

const AndriodIcon = styled(Box)`
    margin-left: 170px;
    margin-top: -56px;
  `;
const ImageNew = styled('img')(({ theme }) => ({
    width: '230px',
    height: '460px',
    marginRight: '200px',
    marginTop: '75px',
    [theme.breakpoints.down('lg')]:{
        marginRight: '90px',
        marginTop: '75px',
      },
    [theme.breakpoints.down('sm')]: {
        width: '200px',
        height: '400px',
        marginLeft: '95px',
        marginTop: '20px',
    }
}))

const ImageSend = styled('img')({
    width:'40px',
    height:'40px',
  })




const Mobile = () => {
    return (
        <>
            <House />

            <MainBox>
                <Box>
                    <TextBox>Need to find a trusted mechanic? We can help.</TextBox>
                    <TextBoxGet>At Roadsiderx, we vet and personal investigation all our specialist co-ops so you can take advantage of a confided in organization of mechanics. Essentially download our application and solicitation a versatile repairman to come to you with the bit of a button. Get a good deal on tows to shops, or save time while getting your oil changed at home with our helpful assistance.</TextBoxGet>


                    <KartBox>
                        <TypeTextBox>Download Now </TypeTextBox>
                        <AndriodIcon>
                        <ImageSend src='Image/PlayStore.png'  style={{ fontSize: '40px', color:'#555555', marginTop:'4px' }} />
                        </AndriodIcon>
                    </KartBox>

                    <KartBox>
                        <TypeTextBox>Download Now </TypeTextBox>
                        <AndriodIcon>
                        <ImageSend src="Image/Andriod.png" alt="" style={{fontSize: '40px', color: '#3DDC84'}}/>
                        </AndriodIcon>
                    </KartBox>

                </Box>
                <ImageNew src="Image/Image-1.png" alt="" />
            </MainBox>


            <FlatSecond />
        </>
    )
}

export default Mobile
