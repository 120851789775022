import React from 'react'
import House from '../Header/House'

import { Box, styled, Typography } from '@mui/material'


const MainBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '400px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]: {
        marginTop: '-100px',
        display: 'block',
        height: '100px',

    }
}))

const TextMain = styled(Typography)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    color: 'white',
    fontSize: '25px',
    lineHeight: '1.2',
    fontWeight: '600',
    textAlign: 'center',
    marginLeft: '670px',
    marginTop: '170px',
    [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
        marginLeft: '-15px',
        marginTop: '100px',
        fontSize: '30px',
        padding: '26px'
    }
}))


const AtTextFild = styled(Typography)(({ theme }) => ({
    color: '#F15A24',
    fontSize: '25px',
    fontFamily: "'Lato', sans-serif",
    fontWeight: '600',
    textTransform: 'none',
    marginTop: '50px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
        marginTop: '15px',
   
    }
}))

const TheTextSec = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontFamily: "'Lato', sans-serif",
    width: '70%',
    marginLeft: '220px',
    marginBottom: '5px',
    marginTop: '0px',
    fontWeight: '500',
    textTransform: 'none',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        fontSize: '10px',
        marginLeft: '0',
        padding: '5px',
        marginBottom: '0',
    }
}))


const Privecy = () => {
    return (
        <>
            <House />

            <MainBox>
                <Box>
                    <TextMain>“Privacy Policy”</TextMain>
                </Box>
            </MainBox>

            <Box>
                <AtTextFild>Personal Data processed for the following purposes and using the following services:</AtTextFild>
                <TheTextSec> Access to third-party accounts.</TheTextSec>
                <TheTextSec>	Twitter account access</TheTextSec>
                <TheTextSec>Personal Data: various types of Data as specified in the privacy policy of the service</TheTextSec>
                <TheTextSec>	Advertising</TheTextSec>

                <TheTextSec>	NextRoll, Facebook Lookalike Audience and Google Ad Manager</TheTextSec>

                <TheTextSec>Personal Data: Cookies; Usage Data</TheTextSec>
                <TheTextSec>	Facebook Audience Network</TheTextSec>
                <TheTextSec>Personal Data: Cookies; unique device identifiers for advertising (Google Advertiser ID or IDFA, for example); Usage Data</TheTextSec>

                <TheTextSec>	JSDELIVR</TheTextSec>

                <TheTextSec>Personal Data: Tracker; Usage Data</TheTextSec>

                <TheTextSec>	Analytics</TheTextSec>

                <TheTextSec>Google Analytics with anonymized IP, Facebook Ads conversion tracking (Facebook pixel), Google Ads conversion tracking, Google Analytics, Twitter Ads conversion tracking and Wordpress Stats</TheTextSec>

                <TheTextSec>Personal Data: Cookies; Usage Data</TheTextSec>

                <TheTextSec>LinkedIn conversion tracking (LinkedIn Insight Tag)</TheTextSec>

                <TheTextSec>Personal Data: Cookies; device information; Usage Data</TheTextSec>

                <TheTextSec>	Facebook Analytics for Apps</TheTextSec>

                <TheTextSec>Personal Data: Usage Data; various types of Data as specified in the privacy policy of the service</TheTextSec>

                <TheTextSec>Beta Testing</TheTextSec>

                <TheTextSec>TestFlight</TheTextSec>
                <TheTextSec>Personal Data: app information; Data communicated while using the service; device information; device logs; email address</TheTextSec>

                <TheTextSec>Contacting the User</TheTextSec>

                <TheTextSec>Contact form</TheTextSec>
                <TheTextSec>Personal Data: city; email address; first name; last name; phone number; state</TheTextSec>

                <TheTextSec>Mailing list or newsletter</TheTextSec>

                <TheTextSec>Personal Data: address; company name; email address; first name; last name; phone number; state; ZIP/Postal code</TheTextSec>

                <TheTextSec>	Content commenting</TheTextSec>

                <TheTextSec>Facebook Comments</TheTextSec>
                <TheTextSec>Personal Data: Cookies; Usage Data</TheTextSec>

                <TheTextSec>Content performance and features testing (A/B testing)</TheTextSec>
                <TheTextSec>Google Optimize and Google Optimize 360</TheTextSec>

                <TheTextSec>Personal Data: Cookies; Usage Data</TheTextSec>

                <TheTextSec>Device permissions for Personal Data access</TheTextSec>
                <TheTextSec>Personal Data: Call permission; Phone permission; Precise location permission (continuous); SMS permission; Storage permission</TheTextSec>

                <TheTextSec>Displaying content from external platforms</TheTextSec>
                <TheTextSec>Google Fonts</TheTextSec>
                <TheTextSec>Personal Data: Usage Data; various types of Data as specified in the privacy policy of the service</TheTextSec>
                <TheTextSec>Instagram widget, YouTube video widget and Mapbox widget</TheTextSec>
                <TheTextSec>Personal Data: Cookies; Usage Data</TheTextSec>
                <TheTextSec>Font Awesome</TheTextSec>
                <TheTextSec>Personal Data: Usage Data</TheTextSec>

                <TheTextSec>Handling payments</TheTextSec>
                <TheTextSec>PayPal and Stripe</TheTextSec>
                <TheTextSec>Personal Data: various types of Data as specified in the privacy policy of the service</TheTextSec>

                <TheTextSec>Hosting and backend infrastructure</TheTextSec>
                <TheTextSec>Microsoft Azure and Firebase Hosting</TheTextSec>
                <TheTextSec>Personal Data: various types of Data as specified in the privacy policy of the service</TheTextSec>
                <TheTextSec>Firebase Cloud Firestore, Firebase Cloud Functions, Firebase Cloud Storage and Firebase Realtime Database</TheTextSec>
                <TheTextSec>Personal Data: Usage Data; various types of Data as specified in the privacy policy of the service</TheTextSec>

                <TheTextSec>Interaction with external social networks and platforms</TheTextSec>
                <TheTextSec>Twitter Tweet button and social widgets, Facebook Like button and social widgets, LinkedIn button and social widgets and Pinterest “Pin it” button and social widgets</TheTextSec>
                <TheTextSec>Personal Data: Cookies; Usage Data</TheTextSec>
                <TheTextSec>YouTube button and social widgets</TheTextSec>
                <TheTextSec>Personal Data: Usage Data</TheTextSec>

                <TheTextSec>Interaction with live chat platforms</TheTextSec>
                <TheTextSec>Facebook Messenger Customer Chat</TheTextSec>
                <TheTextSec>Personal Data: Cookies; Data communicated while using the service; Usage Data</TheTextSec>
                <TheTextSec>Managing contacts and sending messages</TheTextSec>
                <TheTextSec>Twilio</TheTextSec>
                <TheTextSec>Personal Data: phone number</TheTextSec>


                <TheTextSec>Managing data collection and online surveys</TheTextSec>
                <TheTextSec>Facebook lead ads</TheTextSec>
                <TheTextSec>Personal Data: city; email address; first name; last name; phone number; state; ZIP/Postal code</TheTextSec>

                <TheTextSec>Platform services and hosting</TheTextSec>
                <TheTextSec>WordPress.com</TheTextSec>
                <TheTextSec>Personal Data: various types of Data as specified in the privacy policy of the service</TheTextSec>


                <TheTextSec>Registration and authentication</TheTextSec>
                <TheTextSec>Stripe OAuth, Twitter OAuth and WordPress.com Single Sign On</TheTextSec>
                <TheTextSec>Personal Data: various types of Data as specified in the privacy policy of the service</TheTextSec>
                <TheTextSec>AdRoll, Facebook Remarketing, Google Ads Remarketing and Twitter Remarketing</TheTextSec>
                <TheTextSec>Personal Data: Cookies; Usage Data</TheTextSec>
                <TheTextSec>Facebook Custom Audience and Twitter Tailored Audiences</TheTextSec>
                <TheTextSec>Personal Data: Cookies; email address</TheTextSec>

                <TheTextSec>Tag Management</TheTextSec>
                <TheTextSec>Google Tag Manager</TheTextSec>
                <TheTextSec>Personal Data: Usage Data</TheTextSec>

                <TheTextSec>Traffic optimization and distribution</TheTextSec>
                <TheTextSec>Cloudflare</TheTextSec>
                <TheTextSec>Personal Data: Cookies; various types of Data as specified in the privacy policy of the service</TheTextSec>

                <TheTextSec>Information on opting out of interest-based advertising</TheTextSec>
                <TheTextSec>In addition to any opt-out feature provided by any of the services listed in this document, Users may learn more on how to generally opt out of interest-based advertising within the dedicated section of the Cookie Policy.</TheTextSec>

                <TheTextSec>Further information about the processing of Personal Data</TheTextSec>
                <TheTextSec>RoadsideRx Roadside Assistance</TheTextSec>
                <TheTextSec>This policy applies to www.roadesiderx.com, www.ambulockinc.com, as well any other websites, apps or other platforms offered by RoadsideRx.</TheTextSec>

                <TheTextSec>CallRail</TheTextSec>
                <TheTextSec>We use Call Rail to collect caller information when you call our services. CallRail privacy policy can be found at htts://www.callrail.com/privacy/ Call Rail may record phone calls that you make to RoadsideRx and may gather location information regarding where you are calling from.</TheTextSec>

                <TheTextSec>GoodHire</TheTextSec>
                <TheTextSec>We use GoodHire to perform background checks on candidates for delivering our services. The GoodHire privacy policy can be found at https://www.goodhire.com/privacy.</TheTextSec>

                <TheTextSec>Selling goods and services online</TheTextSec>
                <TheTextSec>The Personal Data collected are used to provide the User with services or to sell goods, including payment and possible delivery.
                </TheTextSec>
                <TheTextSec>The Personal Data collected to complete the payment may include the credit card, the bank account used for the transfer, or any other means of payment envisaged. The kind of Data collected by this Application depends on the payment system used.</TheTextSec>

                <AtTextFild>Contact information </AtTextFild>

                <TheTextSec>Ambulock Incorporated, <br></br>
                    Dba: RoadsideRx<br></br>
                    C/O Michael Sprague<br></br>
                    1310 Morningside Ct SE <br></br>
                    Decatur, Al 356021<br></br>
                    info@roadsiderx.com</TheTextSec>

            </Box>

        </>
    )
}

export default Privecy
