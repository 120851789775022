import React from 'react'
import EditIcon from '@mui/icons-material/Edit';
import FadeLoader from "react-spinners/FadeLoader";
import PaymentIcon from '@mui/icons-material/Payment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestoreIcon from '@mui/icons-material/Restore';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import { Box, styled, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {

    useNavigate,
    useParams
} from "react-router-dom";



const MainBox = styled(Box)(({theme})=>({
    display : 'flex',
    background: '#F3F7F8',
    [theme.breakpoints.down('lg')]:{
        display : 'flex',
        background: '#F3F7F8',
    },
    [theme.breakpoints.down('sm')]:{
        display:'block',
        marginTop:'-55px',
        height: '100%',
    }
}))

const ProfileBox = styled(Box)(({theme})=>({
    width: '325px',
    height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '165px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'35px',
       
    }
}))

const ImageBox = styled(Box)(({theme})=>({
    marginLeft: '105px',
    marginTop: '50px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '75px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'105px',
        paddingTop:'30px',
    }
}))

const NameText = styled(Typography)`
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const NumberText = styled(Typography)`
    text-align: center;
    margin-top: 3px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const MainLogoutBox = styled(Box)`
  display: flex;
`

const LogotText = styled(Typography)`
  margin-left: 5px;
  margin-top: 14px;
  color: #F15A24;
`

const MainLogoutBoxSec = styled(Box)(({theme})=>({
    display: 'flex',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]:{
        
    }
}))

const IconBox = styled(Box)(({theme})=> ({
    color: '#F15A24', 
    marginTop: '15px',
    marginLeft: '120px', 
   [theme.breakpoints.down('lg')]:{
     marginLeft: '93px', 
   },
   [theme.breakpoints.down('sm')]:{
        marginLeft:'120px'
   }
}))

const IconBoxSec = styled(Box)(({theme})=>({
    color: '#F15A24', 
    marginTop: '2px', 
    marginLeft: '75px',
    [theme.breakpoints.down('lg')]:{
        marginLeft:'63px',
        marginTop: '0px', 
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'95px'
    }
}))

const LogotTextSec = styled(Typography)(({theme})=>({
    marginLeft: '25px',
    marginTop: '2px',
    color: '#F15A24',
    [theme.breakpoints.down('lg')]:{
        marginTop: '0px',
    }
}))


const ProfileBoxSec = styled(Box)(({theme})=>({
    width: '825px',
    height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '53px',
    [theme.breakpoints.down('lg')]:{
        width: '565px',
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '325px',
        marginLeft: '35px',
        height: '350px',
    }
}))

// const VehicleMode = styled(Typography)`
//     text-align: center;
//     margin-top: 50px;
//     color: black;
//     font-size: 16px;
//     font-weight: 600;
// `

const VehicleBox = styled(Box)(({theme})=> ({
    display:'flex',
    marginTop: '30px',
    [theme.breakpoints.down('lg')]:{
        overflow:'scroll'
    },
    [theme.breakpoints.down('sm')]:{
        overflow:'scroll'
    }
}))


const BoxVehicle = styled(Box)`
    width: 120px;
    height: 120px;
    background: #F3F7F8;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    border-radius: 10px;
    margin: 0px;
    margin-left: 20px;
    margin-top: 20px;
    cursor: pointer;
`

const ImageIcon = styled('img')({
    width: '35px',
    marginLeft: '40px',
    marginTop: '10px',
})

const TextShip = styled(Typography)`
    margin-left: 15px;
    font-family: 'Lato', sans-serif;
    margin-top: 5px;
    font-size: 11px;
    font-weight: 600;
`

const TextShipSec = styled(Typography)`
    margin-left: 15px;
    font-family: 'Lato', sans-serif;
    margin-top: 0px;
    font-size: 8px;
    font-weight: 400;
`

const MapBox = styled(Box)`
    display: flex;
    margin-left: 20px;
    margin-top: -5px;
`

const TextShipTh = styled(Typography)`
    margin-left: 0px;
    font-family: 'Lato', sans-serif;
    margin-top: 5px;
    font-size: 11px;
    font-weight: 600;
`

const TireText = styled(Typography)(({theme})=> ({
    textAlign: 'center',
    fontSize: '18px',
    fontFamily: "'Lato', sans-serif",
    color: '#F15A24',
    marginTop: '60px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]:{
        textAlign: 'center',
        fontSize: '18px',
        fontFamily: "'Lato', sans-serif",
        color: '#F15A24',
        marginTop: '60px',
        fontWeight: '600',
    },
    [theme.breakpoints.down('sm')]:{
        paddingTop:'55px',
    }
}))


const LoaderBox = styled(Box)(({theme})=>({
    marginLeft: '390px',
    marginTop: '60px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '255px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft: '140px',
    }
}))

const NameTextTh = styled(Typography)`
    text-align: center;
    margin: 4px;
    margin-top: -5px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`


const FlatTireOption = () => {
    const navigate = useNavigate();
    const search = window.location.search;
    const params = new URLSearchParams(search)
    let vtype = params.get("vtype");
    let servtype = params.get("servtype");
    let subservname = params.get("subservname");
    const userid =  localStorage.getItem('userid');
    const [storeImage, setStoreImage] = useState([])
    // alert(params);

    const [FlatOption, setFlatOption] = useState([]);
    const [loading, setLoading] = useState(false)
    const getFlatOption = async () => {
        const response = await fetch(`https://demo.towbooker.com/api/get-sub-service?id=${servtype}`)
        const data = await response.json();
        setFlatOption(data.data)
    }
    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 1500)
    }, [])

    const selectsubservice = (id, name, image, price) => {
        navigate(`/flattirebooking?vtype=${vtype}&&servtype=${servtype}&&subsurvice=${id}&&subservname=${name}&&image=${image}&&price=${price}`);
    }

    useEffect(() => {
        getFlatOption()
    }, [])

    const [userName, setUserName] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        profile_photo:''
    })

    const ProfileImageShow = storeImage.map(item => item.imgcro)
       
    const userSetData = async () => {
       const response = await fetch(`https://demo.towbooker.com/api/viewUserbyid?userid=${userid}`)
        const data = await response.json();
        //console.log(data);
       
       setUserName(data.data)
       // localStorage.setItem('username', res.data.message);
        // console.log(data.data) 
         
    }

    useEffect(() => {
        userSetData();
    }, [])




    return (
        <>
            <MainBox>
            <ProfileBox>
                    <ImageBox>
                        <div className="flex-shrink-0">
                            <img src={ProfileImageShow}
                                alt="Generic placeholder image" className="img-fluid"
                                style={{ width: '110px', borderRadius: '50%' }} />
                        </div>
                    </ImageBox>
            
                        <NameText>{userName.first_name}</NameText>
                        <NameTextTh>{userName.last_name}</NameTextTh>
                   
                    <NumberText>{userName.mobile_number}</NumberText>
                    <NumberText>{userName.email}</NumberText>
                    <MainLogoutBox>
                        <IconBox>
                        <EditIcon style={{fontSize: '22px' }} />
                        </IconBox>
          
                        <LogotText>Edit</LogotText>
                    </MainLogoutBox>
                    <hr />
                    <MainLogoutBoxSec>
                        <IconBoxSec>
                        <AccountCircleIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
                
                        <LogotTextSec>Profile</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                        <AccessTimeIcon style={{  fontSize: '22px' }} />
                        </IconBoxSec>
                     
                        <Link to='/booking' style={{ textDecoration: 'none' }} >
                            <LogotTextSec>Bookings</LogotTextSec>
                        </Link>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                        <PaymentIcon style={{fontSize: '22px' }} />
                        </IconBoxSec>
               
                        <LogotTextSec>Payments</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                        <RestoreIcon style={{fontSize: '22px' }} />
                        </IconBoxSec>
                 
                        <LogotTextSec>Transactions</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                        <StarBorderPurple500Icon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
                 
                        <LogotTextSec>Review</LogotTextSec>
                    </MainLogoutBoxSec>

                </ProfileBox>


                <ProfileBoxSec>
                    <TireText>{subservname}</TireText>
                    {
                        loading ?
                            <LoaderBox>
                                <FadeLoader
                                    color={'#F18180'}
                                    loading={loading}

                                    size={80}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </LoaderBox> :
                            <VehicleBox>
                                {
                                    FlatOption.map((OptionFlat, index) => {
                                        let IconFlat = 'https://demo.towbooker.com/public/images/' +
                                            OptionFlat.image;
                                        return (
                                            <Box onClick={() => { selectsubservice(OptionFlat.id, OptionFlat.title, IconFlat, OptionFlat.price) }} key={index}>
                                                <BoxVehicle>
                                                    <ImageIcon src={IconFlat} alt="" />
                                                    <TextShip>{OptionFlat.title}</TextShip>
                                                    <TextShipSec>{OptionFlat.description}</TextShipSec>
                                                    <MapBox>
                                                        <TextShipTh>{OptionFlat.currency_code}</TextShipTh>
                                                        <TextShipTh>{OptionFlat.price}/Agent/</TextShipTh>
                                                        <TextShipTh>{OptionFlat.time}</TextShipTh>
                                                    </MapBox>

                                                </BoxVehicle>
                                            </Box>
                                        )
                                    })
                                }
                            </VehicleBox>
                    }


                </ProfileBoxSec>
            </MainBox>
        </>
    )
}

export default FlatTireOption
