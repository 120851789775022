import React from 'react'

import House from '../Header/House'

import { Box, styled, Typography } from '@mui/material'
import FlatSecond from './FlatSecond';


const MainBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '600px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]:{
        display: 'flex',
      },
    [theme.breakpoints.down('sm')]: {
        marginTop: '-10px',
        display: 'block',
        height: '830px',
        overflow: 'hidden',
    }
}))

const TextBox = styled(Box)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    fontSize: '25px',
    wordWrap: 'break-word',
    marginTop: '95px',
    marginLeft: '170px',
    letterSpacing: '2px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]:{
        fontSize: '25px',
        marginTop: '105px',
        marginLeft: '100px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        wordWrap: 'break-word',
        padding: '25px',
        marginTop: '10px',
        marginLeft: '10px',
        letterSpacing: '2px',
        fontWeight: '400',
    }
}))


const TextBoxGet = styled(Box)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    width: '39%',
    fontSize: '16px',
    marginTop: '10px',
    wordWrap: 'break-word',
    marginLeft: '170px',
    letterSpacing: '1px',
    lineHeight:'22px',
    fontWeight: '500',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]:{
        width: '80%',
        fontSize: '16px',
        marginLeft: '100px',
        lineHeight:'20px', 
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        fontSize: '10px',
        marginTop: '-20px',
        wordWrap: 'break-word',
        marginLeft: '35px',
        letterSpacing: '1px',
        fontWeight: '400',
        lineHeight:'15px',  
        
    }
}))


const KartBox = styled(Box)(({ theme }) => ({
    width: '240px',
    height: '70px',
    background: '#F1F3F6',
    marginLeft: '170px',
    marginTop: '25px',
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '100px',
        marginTop: '30px',
      },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '75px',
        marginTop: '30px',
    }

}))


const TypeTextBox = styled(Box)`
    color: #F15A24;
    margin-left: 17px;
    width: 100%;
    padding: 22px;
    font-size: 16px;
  `;

const AndriodIcon = styled(Box)`
    margin-left: 170px;
    margin-top: -56px;
  `;




const ImageNew = styled('img')(({ theme }) => ({
    width: '230px',
    height: '460px',
    marginRight: '200px',
    marginTop: '75px',
    [theme.breakpoints.down('lg')]:{
        marginRight: '90px',
        marginTop: '75px',
      },
    [theme.breakpoints.down('sm')]: {
        width: '200px',
        height: '400px',
        marginLeft: '95px',
        marginTop: '40px',
    }
}))

const TairMainBox = styled(Box)(({theme})=> ({
    display: 'flex',
    marginLeft: '350px',
    marginTop: '100px',
    [theme.breakpoints.down('lg')]:{
        display: 'flex',
        marginLeft: '75px',
        marginTop: '60px',
        marginBottom: '80px',
      },
    [theme.breakpoints.down('sm')]: {
        display: 'block',
        marginLeft: '20px',
    }
}))


const ImageFont = styled('img')(({theme})=> ({
    width: '770px',
    height: '250px',
    [theme.breakpoints.down('lg')]:{
        width: '450px',
        height: '350px',
      },
    [theme.breakpoints.down('sm')]: {
        width: '300px',
        height: '200px',
        marginTop: '-60px',
        marginLeft: '25px',
    }
}))


const TexFild = styled(Typography)(({theme})=> ({
    fontFamily: "'Lato', sans-serif",
    marginTop: '10px',
    width: '50%',
    marginLeft: '30px',
    color: 'grey',
    fontSize: '16px',
    lineHeight: '28px',
    fontFamily: "'Open Sans', sans-serif",
    [theme.breakpoints.down('lg')]: {
        width: '80%',
        marginLeft: '30px',
      },
    [theme.breakpoints.down('sm')]: {
        width: '90%',
        textAlign: 'center',
        fontSize: '15px',
        marginLeft: '5px'
    }
}))
  


const JumbText = styled(Typography)(({theme})=> ({
    color:'#F15A24',
    fontFamily: "'Lato', sans-serif",
    marginTop: '20px',
    fontSize: '20px',
    marginLeft: '30px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]: {
        marginTop: '68px',
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '90px'
    }
}))

const ImageSend = styled('img')({
    width:'40px',
    height:'40px',
  })






const OutFull = () => {
    return (
        <>
            <House />

            <MainBox>
                <Box>
                    <TextBox>Run out of fuel? We can help.</TextBox>
                    <TextBoxGet>Running out of fuel is a bummer. As are the insane exorbitant costs others charge to present to several gallons. With Roadsiderx we assist with getting you going quick and at fair market costs, so you won't ever pay $100 for two gallons of gas. Download our application and we'll get you going once more!</TextBoxGet>

                    <KartBox>
                        <TypeTextBox>Download Now </TypeTextBox>
                        <AndriodIcon>
                        <ImageSend src='Image/PlayStore.png'  style={{ fontSize: '40px', color:'#555555', marginTop:'4px' }} />
                        </AndriodIcon>
                    </KartBox>

                    <KartBox>
                        <TypeTextBox>Download Now </TypeTextBox>
                        <AndriodIcon>
                        <ImageSend src="Image/Andriod.png" alt="" style={{fontSize: '40px', color: '#3DDC84'}}/>
                        </AndriodIcon>
                    </KartBox>

                </Box>
                <ImageNew src="Image/Image-1.png" alt="" />
            </MainBox>

            <Box>
                <TairMainBox>
                    <ImageFont src="Image/4.jpg" alt="" />
                    <Box>
                        <JumbText>Fuel Delivery</JumbText>
                        <TexFild>Out of Fuel. Our roadside technicians will bring you 2.5 gallons on unleaded fuel or 5 gallons of diesel fuel.  Out of Fuel. Our roadside technicians will bring you 2.5 gallons on unleaded fuel or 5 gallons of diesel fuel.   </TexFild>
                    </Box>
                </TairMainBox>
            </Box>


            <FlatSecond />
        </>
    )
}

export default OutFull
