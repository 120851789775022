import React from 'react'
import { Box, styled, Typography } from '@mui/material'

const MainBox = styled(Box)`
    width: 100%;
    height: 400px;
    background: #FFFFFF;
`

const SecondBox = styled(Box)(({theme})=> ({
    width: '100%',
    height: '300px',
    //border: 2px solid red;
    marginTop: '90px',
    [theme.breakpoints.down('lg')]:{
        width: '100%',
        height: '300px',
        //border: 2px solid red;
        marginTop: '90px',
    },
    [theme.breakpoints.down('sm')]:{
        marginTop:'-45px'
    }
}))


const BookingText = styled(Typography)`
    text-align: center;
    color: #ed471f;
    font-size: 40px;
    line-height: 60px;
`

const ForText = styled(Typography)`
    margin: 10px 0;
    font-family: Lato,sans-serif;
    font-size: 28px;
    line-height: 32px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 30px;
`

const DownloadText = styled(Typography)`
    color: #000;
    text-align: center;
    margin-top: 30px;
`

const IamgeBox = styled(Box)(({theme})=> ({
    display: 'flex',
    marginTop: '40px',
    marginLeft: '510px',
    [theme.breakpoints.down('lg')]:{
        marginLeft:'270px',
    },
    [theme.breakpoints.down('sm')]:{
        display:'block',
        marginLeft:'79px',
    }
}))



const ImageStyle = styled('img')({
    width:'233px',
    height:'70px',
})

const SecImageStyle = styled('img')(({theme})=> ({
    width:'233px',
    height:'70px',
    marginLeft:'15px',
    [theme.breakpoints.down('lg')]:{
        width:'233px',
        height:'70px',
        marginLeft:'15px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'0px',
        marginTop:'10px',
    }
}))




const SubFooter = () => {
  return (
    <>
        <MainBox>
            <SecondBox>
                <BookingText>BOOKING</BookingText>
                <ForText>FOR QUICK BOOKING DOWNLOAD OUR APP.</ForText>
                <DownloadText>Download our App to book your roadside assistance service on demand and track your service.</DownloadText>
                <IamgeBox>
                    <ImageStyle src="http://carcays.net/public/web/images/google.png" alt="" />
                    <SecImageStyle src="http://carcays.net/public/web/images/apple.webp" alt="" />
                </IamgeBox>
            </SecondBox>
       
        </MainBox> 
    </>
  )
}

export default SubFooter
