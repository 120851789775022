import React from 'react'
import { Box, Typography, styled } from '@mui/material'
import GoogleIcon from '@mui/icons-material/Google';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import StarIcon from '@mui/icons-material/Star';



const MainBox = styled(Box)(({ theme }) => ({
    marginTop: '25px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '40px',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '75px',
    }
}))



const Welcometype = styled(Typography)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    color: '#F15A24',
    marginTop: '40px',
    fontSize: '25px',
    lineHeight: '60px',
    fontWeight: '600',
    textTransform: 'none',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
        color: '#29398d',
        fontSize: '25px',
        lineHeight: '60px',
        fontWeight: '600',
        textTransform: 'none',
        textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '-140px',
        fontSize: '18px',
    }
}))


const SideBox = styled(Box)(({ theme }) => ({
    width: '100%',
    marginTop: '25px',
    marginBottom: '65px',
    marginLeft: '0px',

    [theme.breakpoints.down('lg')]: {
        width: '100%',
        marginTop: '45px',
        marginBottom: '65px',
        marginLeft: '0px',

    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: '0px',
        marginTop: '10px'
    }
}))




const ReviewMainBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginLeft: '105px',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
        margin: '25px',
    },
    [theme.breakpoints.down('sm')]: {
        display: 'block',
        marginLeft: '36px',

    }
}))



const WeidthBox = styled(Box)(({ theme }) => ({
    width: '300px',
    height: '300px',
    background: 'white',
    marginLeft: '25px',
    marginTop: '10px',
    marginBottom: '10px',
    boxShadow: "0.3em 0.3em 1em rgba(0, 0, 0, 0.3)",
    [theme.breakpoints.down('lg')]: {
        width: '260px',
        height: '280px',
        background: 'white',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '-25px',
        marginBottom: '0px',
        boxShadow: "0.3em 0.3em 1em rgba(0, 0, 0, 0.3)",
    },
    [theme.breakpoints.down('sm')]: {
        width: '250px',
        height: '300px',
        background: 'white',
        margin: '9px',
        marginLeft: '35px',
    }
}))



const GoogleFontStyleIcon = styled(Box)(({ theme }) => ({
    marginLeft: '130px',
    marginTop: '15px',
    color: 'red',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '100px',
        marginTop: '15px',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '20px',
        marginLeft: '90px',
    }
}))



const ReviewText = styled(Box)`
    margin-left: 15px;
    margin-top: -15px;
    color: #777!important;
    line-height: 24px!important;
    font-size: 13px;
    font: inherit!important;
    vertical-align: baseline!important;
`

const ReviveTextSec = styled(Typography)`
    font-size: 13px;
    padding: 10px;
`




const Five = () => {
    return (
        <React.Fragment>
            <MainBox>
                <Box>
                    <Welcometype>What Our Customers Have to Say</Welcometype>
                </Box>


                <SideBox>

                    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">

                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <Box>
                                    <ReviewMainBox>
                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                        Michael was great he was nice, easy going he replaced my car keys for a low price i would definitely recommend him to others and most definitely be calling him again in the future if i have any problems.
                                                    </ReviveTextSec>
                                                </Box>
                                            </ReviewText>

                                        </WeidthBox>

                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                        Michael was great he was nice, easy going he replaced my car keys for a low price i would definitely recommend him to others and most definitely be calling him again in the future if i have any problems.
                                                    </ReviveTextSec>
                                                </Box>
                                            </ReviewText>

                                        </WeidthBox>


                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px' , marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                        Michael was the nicest most down to earth guy i've ever seen working at 1:00AM, Great Prices, Great response time, Great guy to be around. if there a way into the lock you need into, he,s the guy i'd call. i'll definitely call him out again if anything happens.
                                                    </ReviveTextSec>

                                                </Box>
                                            </ReviewText>
                                        </WeidthBox>


                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                        Ambulock did a fantastic job. They cut a key for me on the weekend at a reasonable rate. i look forwrad to doing bussiness with mike in future. 10/10
                                                    </ReviveTextSec>

                                                </Box>
                                            </ReviewText>
                                        </WeidthBox>
                                    </ReviewMainBox>
                                </Box>
                            </div>
                            <div className="carousel-item">

                                <Box>
                                    <ReviewMainBox>
                                    <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                        Michael was great he was nice, easy going he replaced my car keys for a low price i would definitely recommend him to others and most definitely be calling him again in the future if i have any problems.
                                                    </ReviveTextSec>
                                                </Box>
                                            </ReviewText>

                                        </WeidthBox>
                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px' , marginTop:'15px'}} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                    Mike was prompt to answer my call for help with a dead battery. Cost was good will definitely use again and will be sure family members have his number.
                                                    </ReviveTextSec>
                                                 
                                                </Box>
                                            </ReviewText>
                                        </WeidthBox>


                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                    THEY  WERE ON TIME, VERY COURTEOUS AND COMPLETED THE JOB IN A TIMELY MANNER. replaced my car keys for a low price i would definitely recommend him to others and most definitely be calling
                                                    </ReviveTextSec>
                                                 
                                                </Box>
                                            </ReviewText>
                                        </WeidthBox>


                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                    Got here fast, got my keys out of my car was a berry nice guy will use replaced my car keys for a low price i would definitely recommend him to others and most definitely be calling again!! 
                                                    </ReviveTextSec>
                                                   
                                                </Box>
                                            </ReviewText>
                                        </WeidthBox>
                                    </ReviewMainBox>
                                </Box>
                            </div>
                            <div className="carousel-item">
                                <Box>
                                    <ReviewMainBox>
                                    <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                        Michael was great he was nice, easy going he replaced my car keys for a low price i would definitely recommend him to others and most definitely be calling him again in the future if i have any problems.
                                                    </ReviveTextSec>
                                                </Box>
                                            </ReviewText>

                                        </WeidthBox>
                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                    11/10 , super friendly and helpful! Great prices !! Definitely my go to guy from now on replaced my car keys for a low price i would definitely recommend him to others and most definitely be calling
                                                    </ReviveTextSec>
                                                   
                                                </Box>
                                            </ReviewText>
                                        </WeidthBox>


                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                    Nice guy, very informative and is really good at what he does. Will definitely recommend to anybody who needs a locksmith! replaced my car keys for a low price i would definitely recommend him to others and most definitely be calling
                                                    </ReviveTextSec>
                                                
                                                </Box>
                                            </ReviewText>
                                        </WeidthBox>


                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                    Michael Sprague was a great help and was also nice and polite!  replaced my car keys for a low price i would definitely recommend him to others and most definitely be calling
                                                    </ReviveTextSec>
                                              
                                                </Box>
                                            </ReviewText>
                                        </WeidthBox>
                                    </ReviewMainBox>
                                </Box>
                            </div>
                            <div className="carousel-item">
                                <Box>
                                    <ReviewMainBox>
                                    <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                        Michael was great he was nice, easy going he replaced my car keys for a low price i would definitely recommend him to others and most definitely be calling him again in the future if i have any problems.
                                                    </ReviveTextSec>
                                                </Box>
                                            </ReviewText>

                                        </WeidthBox>
                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                    Great Fast Service  replaced my car keys for a low price i would definitely recommend him to others and most definitely be calling  Michael was great, he was nice, easy going he replaced my car 
                                                    </ReviveTextSec>
                                                   
                                                </Box>
                                            </ReviewText>
                                        </WeidthBox>


                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px' , marginTop:'15px'}} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                    Michael was great, he was nice, easy going he replaced my car keys for a low price I would definitely recommend him to others and most definitely be calling him again in the future if I have any problems.
                                                    </ReviveTextSec>
                                           
                                                </Box>
                                            </ReviewText>
                                        </WeidthBox>


                                        <WeidthBox>
                                            <GoogleFontStyleIcon>
                                                <GoogleIcon style={{ fontSize: '30px' }} />
                                            </GoogleFontStyleIcon>
                                            <ReviewText>
                                                <Box>
                                                    <FormatQuoteIcon style={{ color: 'LightGray', fontSize: '30px', marginTop:'15px' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    < StarIcon style={{ color: 'rgb(231, 113, 27)' }} />
                                                    <ReviveTextSec>
                                                    Michael was the nicest most down to earth guy I’ve ever seen working at 1:00AM, Great prices, Great response time, Great guy to be around. If there’s a way into the lock you need into, he’s the guy I’d call. I’ll definitely call him out again if anything happens.
                                                    </ReviveTextSec>
                                                  
                                                </Box>
                                            </ReviewText>
                                        </WeidthBox>
                                    </ReviewMainBox>
                                </Box>
                            </div>
                        </div>
                        <a className="carousel-control-prev " href="#carouselExampleIndicators" role="button" data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </SideBox>
            </MainBox>

        </React.Fragment>
    )
}

export default Five
