import React from 'react'
import House from '../Header/House'
import { Box, styled, Typography } from '@mui/material'
import FlatSecond from './FlatSecond';


const MainBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '600px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    color: '#fff',
    overflow: 'hidden',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]:{
        display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '-10px',
        display: 'block',
        height: '760px',
        overflow: 'hidden',
    }
}))

const TextBox = styled(Box)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    fontSize: '25px',
    wordWrap: 'break-word',
    marginTop: '105px',
    marginLeft: '170px',
    letterSpacing: '2px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]:{
        fontSize: '25px',
        marginTop: '120px',
        marginLeft: '100px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        wordWrap: 'break-word',
        padding: '25px',
        marginTop: '10px',
        marginLeft: '27px',
        letterSpacing: '2px',
        fontWeight: '400',
    }
}))


const TextBoxGet = styled(Box)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    width: '32%',
    fontSize: '16px',
    marginTop: '10px',
    wordWrap: 'break-word',
    marginLeft: '170px',
    letterSpacing: '1px',
    lineHeight:'22px',
    fontWeight: '500',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]:{
        width: '80%',
        fontSize: '16px',
        marginLeft: '100px',
        lineHeight:'20px', 
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        marginTop: '-20px',
        wordWrap: 'break-word',
        marginLeft: '16px',
        width: '90%',
        textAlign: 'center',
        letterSpacing: '1px',
        fontWeight: '400',
        lineHeight:'15px',  
    }
}))


const KartBox = styled(Box)(({ theme }) => ({
    width: '240px',
    height: '70px',
    background: '#F1F3F6',
    marginLeft: '170px',
    marginTop: '45px',
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '100px',
        marginTop: '50px',
      },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '75px',
        marginTop: '20px',
    }

}))

const KartBoxSec = styled(Box)(({ theme }) => ({
    width: '240px',
    height: '70px',
    background: '#F1F3F6',
    marginLeft: '170px',
    marginTop: '15px',
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '100px',
     
      },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '75px',
        marginTop: '15px',
    }

}))


const TypeTextBox = styled(Box)`
    color: #F15A24;
    margin-left: 17px;
    width: 100%;
    padding: 22px;
    font-size: 16px;
  `;

const AndriodIcon = styled(Box)`
    margin-left: 170px;
    margin-top: -56px;
  `;

const ImageNew = styled('img')(({ theme }) => ({
    width: '230px',
    height: '460px',
    marginRight: '200px',
    marginTop: '75px',
    [theme.breakpoints.down('lg')]:{
        marginRight: '90px',
        marginTop: '75px',
      },
    [theme.breakpoints.down('sm')]: {
        width: '200px',
        height: '400px',
        marginLeft: '95px',
        marginTop: '30px',
    }
}))


const TairMainBox = styled(Box)(({theme})=> ({
    display: 'flex',
    marginLeft: '340px',
    marginTop: '100px',
    [theme.breakpoints.down('lg')]:{
        display: 'flex',
        marginLeft: '75px',
        marginTop: '60px',
        marginBottom: '80px',
      },
    [theme.breakpoints.down('sm')]: {
        display: 'block',
        marginLeft: '22px',
        marginTop: '30px',
    }
  
}))


const ImageFont = styled('img')(({theme})=> ({
    width: '1070px',
    height: '250px',
    [theme.breakpoints.down('lg')]:{
        width: '450px',
        height: '350px',
      },
    [theme.breakpoints.down('sm')]: {
        width: '300px',
        height: '180px',
        marginLeft:'23px',
    }
}))





const TexFild = styled(Typography)(({theme})=> ({
    marginTop: '10px',
    width: '50%',
    marginLeft: '30px',
    color: 'grey',
    fontSize: '16px',
    lineHeight: '28px',
    fontFamily: "'Lato', sans-serif",
    [theme.breakpoints.down('lg')]: {
        width: '80%',
        marginLeft: '30px',
      },
    [theme.breakpoints.down('sm')]: {
        width: '90%',
        fontSize: '15px',
        marginLeft: '4px',
        textAlign:'center',
        lineHeight: '20px',
    }
}))



const JumbText = styled(Typography)(({theme})=> ({
    color:"#F15A24",
    fontFamily: "'Lato', sans-serif",
    marginTop: '20px',
    fontSize: '20px',
    marginLeft: '30px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]: {
        marginTop: '68px',
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '15px',
        fontSize: '18px',
    }
}))




const TairMainBoxSec = styled(Box)(({theme})=> ({
    display: 'flex',
    marginLeft: '340px',
    marginTop: '30px',
    [theme.breakpoints.down('lg')]:{
        display: 'flex',
        marginLeft: '75px',
        marginTop: '60px',
        marginBottom: '80px',
      },
    [theme.breakpoints.down('sm')]: {
        display: 'block',
        marginLeft: '30px',
        marginTop: '-20px',
    }
}))


const ImageFontSec = styled('img')(({theme})=> ({
    width: '480px',
    height: '380px',
    marginRight: '350px',
    marginTop: '40px',
    [theme.breakpoints.down('lg')]:{
        width: '450px',
        height: '350px',
        marginRight: '75px',
      },
    [theme.breakpoints.down('sm')]: {
        width: '300px',
        height: '180px',
        marginLeft:'23px',
        marginTop: '5px'
    }
}))




const TexFildSec = styled(Typography)(({theme})=> ({
    marginTop: '10px',
    width: '90%',
    marginLeft: '10px',
    color: 'grey',
    fontSize: '16px',
    lineHeight: '28px',
    fontFamily: "'Lato', sans-serif",
    [theme.breakpoints.down('lg')]: {
        width: '80%',
        marginLeft: '0px',
      },
    [theme.breakpoints.down('sm')]: {
        width: '90%',
        marginLeft: '0',
        fontSize: '15px',
        lineHeight: '20px',
        textAlign: 'center',
    }
}))



const JumbTextSec = styled(Typography)(({theme})=> ({
    color:'#F15A24',
    marginTop: '50px',
    fontSize:  '20px',
    marginLeft: '10px',
    fontWeight: '600',
    fontFamily: "'Lato', sans-serif",
    [theme.breakpoints.down('lg')]: {
        marginTop: '35px',
        marginLeft: '0px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        marginLeft: '100px'
    }

}))


const ImageSend = styled('img')({
    width:'40px',
    height:'40px',
  })





const LockOut = () => {
    return (
        <>
            <House />

            <MainBox>
                <Box>
                    <TextBox>Locked out? We can help.</TextBox>
                    <TextBoxGet>We comprehend the need to get going that accompanies being locked out and at Mach1 you can arrange a locksmith with the dash of a button. A specialist co-op will be naturally dispatched to help.</TextBoxGet>


                    <KartBox>
                        <TypeTextBox>Download Now </TypeTextBox>
                        <AndriodIcon>
                        <ImageSend src='Image/PlayStore.png'  style={{ fontSize: '40px', color:'#555555', marginTop:'4px' }} />
                        </AndriodIcon>
                    </KartBox>

                    <KartBoxSec>
                        <TypeTextBox>Download Now </TypeTextBox>
                        <AndriodIcon>
                        <ImageSend src="Image/Andriod.png" alt="" style={{fontSize: '40px', color: '#3DDC84'}}/>
                        </AndriodIcon>
                    </KartBoxSec>
                </Box>
                <ImageNew src="Image/Image-1.png" alt="" />
            </MainBox>

            <Box>
                <TairMainBox>
                    <ImageFont src="Image/2.jpg" alt="" />
                    <Box>
                        <JumbText>Emergency Vehicle Lockout Service</JumbText>
                        <TexFild>Locked out of your vehicle? We will send a licensed locksmith or roadside technician to unlock your car, truck or semi-truck. Locked out of your vehicle? We will send a licensed locksmith or roadside technician to unlock your car, truck or semi-truck. </TexFild>
                    </Box>
                </TairMainBox>
            </Box>

            <Box>
                <TairMainBoxSec>
                    <Box>
                        <JumbTextSec>Home Lockouts</JumbTextSec>
                        <TexFildSec>Getting locked out of your own house is frustrating and it can be downright scary. Because you’re anxious to get into your home quickly, you might take drastic measures and try to break into your own home by breaking a window or a lock. This is the last thing you should do because you’ll cause unnecessary damage to your home that is expensive to repair. Instead, call the professionals at Roadsiderx. We will come to you and can use our special tools to unlock doors and get you safely back into your home. </TexFildSec>
                    </Box>
                    <ImageFontSec src="Image/12.jpeg" alt="" />
                </TairMainBoxSec>
            </Box>

            <FlatSecond />
        </>
    )
}

export default LockOut
