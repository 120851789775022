import React from 'react'
import {Box, styled} from '@mui/material'




const MainBox = styled(Box)(({theme})=>({  
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '600px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '-100px',
        display: 'block',
        height: '860px',
       
    }
}))

const TextBox = styled(Box)(({theme})=> ({
    fontFamily: "'Lato', sans-serif",
    fontSize: '25px',
    wordWrap: 'break-word',
    marginTop: '105px',
    marginLeft: '170px',
    letterSpacing: '1.5px',
    lineHeight:'34px',
    fontWeight: '600',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        fontSize: '25px',
        marginTop: '80px',
        marginLeft: '100px',
        lineHeight:'35px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
        wordWrap: 'break-word',
        padding: '25px',
        marginTop: '100px',
        marginLeft: '40px',
        letterSpacing: '1px',
        fontWeight: '400',
        lineHeight:'25px',
    }
}))







const ImageNew = styled('img')(({theme})=> ({
    width: '230px',
    height: '460px',
    marginLeft: '440px',
    marginTop: '75px',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '65px',
      
    },
    [theme.breakpoints.down('sm')]: {
        width: '200px',
        height: '400px',
        marginLeft: '95px',
        marginTop: '30px',
        overflow: 'hidden',
    }
}))

const KartBox = styled(Box)(({theme})=>({
    width: '300px',
    height: '70px',
    background: 'white',
    marginLeft: '170px',
    marginTop: '35px',
    borderRadius: '8px',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        marginTop: '55px', 
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '45px',
        marginTop: '20px', 
    }
    
}))


const TypeTextBox = styled(Box)`
    color: #F15A24;
    margin-left: 17px;
    width: 100%;
    padding: 22px;
    font-size: 16px;
`;

const AndriodIcon = styled(Box)`
    margin-left: 235px;
    margin-top: -56px;
`
const ImageSend = styled('img')({
    width:'40px',
    height:'40px',
})




const home = () => {
  return (
    <React.Fragment>
        <MainBox>
            <Box> 
                <TextBox>The On-Demand Towbooker Assistance <br/> App, with no membership fee.</TextBox>
             
                <KartBox>
                   <TypeTextBox>(Customer) Get Help Now </TypeTextBox>
                   <AndriodIcon>
                   <ImageSend src="Image/Andriod.png" alt="" style={{fontSize: '40px', color: '#3DDC84'}}/>
                   </AndriodIcon>
                </KartBox>
                <KartBox>
                   <TypeTextBox>(Provider) Download Now </TypeTextBox>
                   <AndriodIcon>
                        <ImageSend src="Image/Andriod.png" alt="" style={{fontSize: '40px', color: '#3DDC84'}}/>
                     
                   </AndriodIcon>
                </KartBox>

                <KartBox>
                   <TypeTextBox>(Download) Customer App </TypeTextBox>
                   <AndriodIcon>
                   <ImageSend src='Image/PlayStore.png'  style={{ fontSize: '40px', color:'#555555', marginTop:'4px' }} />
                   </AndriodIcon>
                </KartBox>

                
            </Box>
            <ImageNew src="Image/Image-1.png" alt="" />
        </MainBox> 

    </React.Fragment>
  )
}

export default home
