import React from 'react'

import { Box, styled, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import House from '../Header/House';

const MainBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://www.shell.com.au/motorists/truck-atlas/_jcr_content/pagePromo/image.img.960.jpeg/1528879489475/truckatlas.jpeg")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '850px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    color: '#fff',
    [theme.breakpoints.down('lg')]: {
        marginTop: '-100px',
        display: 'block',
        height: '800px',
    }
}))

const SecoBox = styled(Box)(({ theme }) => ({
    background: 'white',
    width: '50%',
    height: '760px',
    marginLeft: '400px',
    marginTop: '45px',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        display: 'block',
        width: '100%',
        marginLeft: '0',
        overflow: 'hidden',
        marginTop: '100px',
        height: '850px',
    }
}))


const Image = styled('img')(({ theme }) => ({
    width: '72px',
    height: '72px',
    marginLeft: '345px',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '480px',
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '150px',

    }
}))


const BusseText = styled(Typography)`
    color: black;
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.1;
    
`;

const ThirdBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '95%',
    height: '35px',
    background: '#f5f5f5',
    borderRadius: '8px',
    marginLeft: '18px',
    [theme.breakpoints.down('lg')]:{
        width: '95%',
        height: '50px',
        background: '#f5f5f5',
        borderRadius: '8px',
        marginLeft: '18px',
    },
    [theme.breakpoints.down('sm')]: {
        width: '95%',
        height: '60px',
        marginLeft: '9px',
    }
}))


const PrimeText = styled(Typography)(({theme})=>({
    color: 'black',
    padding: '6px',
    marginLeft: '13px',
    fontSize: '15px',
    [theme.breakpoints.down('lg')]:{
        padding: '16px',
    },
    [theme.breakpoints.down('sm')]:{
        padding: '6px',
    }
}))


const DetailText = styled(Typography)(({theme})=>({
    color: 'black',
    padding: '6px',
    marginLeft: '13px',
    fontSize: '15px',
    [theme.breakpoints.down('lg')]:{
        padding: '16px',
    },
    [theme.breakpoints.down('sm')]:{
        padding: '6px',
    }
}))




const DetailTextPrice = styled(Typography)(({ theme }) => ({
    width: '20%',
    height: '35px',
    color: '#fff',
    backgroundColor: '#337ab7',
    borderColor: '#337ab7',
    textAlign: 'center',
    padding: '6px',
    fontWeight: '500',
    [theme.breakpoints.down('lg')]:{
        height: '50px',
        marginLeft: '10px',
        padding: '12px',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '6px',
        width: '30%',
        height: '60px',
    }
}))


const FromBox = styled(Box)`
    
    padding: 30px;
    color: black;
    font-weight: 600;
`;

const BottomBox = styled(Box)(({ theme }) => ({
    marginLeft: '575px',
    marginTop: '-52px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '200px',
    }
}))


const Insurance = () => {
    return (
        <>
            <House />
            <MainBox>
                <SecoBox>
                    <Image src="Image/Logo.png" alt="" />
                    <BusseText>Business Provider Signup</BusseText>
                    <ThirdBox>
                        <PrimeText>Primary Contact</PrimeText>
                        <DetailText>Business Details</DetailText>
                        <DetailTextPrice>Insurance Details</DetailTextPrice>
                        <PrimeText>Document Details</PrimeText>
                    </ThirdBox>
                    <FromBox>
                        <div className="form-group mt-2">
                            <label for="inputAddress">Insurance Number</label>
                            <input type="number" className="form-control" id="inputAddress" />
                        </div>
                        <div className="form-row mt-2">
                            <div className="col">
                                <label for="inputEmail4">Vehicle Make</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div>


                        <div className="form-row mt-2">
                            <label for="inputEmail4">Vehicle Name</label>
                            <input type="text" className="form-control" />
                        </div>


                        <div className="form-row mt-2">
                            <label for="inputEmail4">Vehicle Model</label>
                            <input type="text" className="form-control" />
                        </div>




                        <div className="form-row mt-2">
                            <div className="col">
                                <label for="inputEmail4">Plate Number</label>
                                <input type="text" className="form-control"  />
                            </div>
                        </div>

                        <div className="form-row mt-2">
                            <label for="inputEmail4">Engine Number </label>
                            <input type="text" className="form-control"  />
                        </div>

                        <div className="form-row">
                            <Link type="submit" to='/business' className="btn btn-primary m-3">Back</Link>
                            <Link type="submit" to='/document' className="btn btn-primary m-3">Next</Link>
                        </div>
                        <BottomBox>
                            <Link type="submit" to='/' className="btn btn-primary">Back to Home</Link>
                        </BottomBox>
                    </FromBox>
                </SecoBox>
            </MainBox>
        </>
    )
}

export default Insurance
