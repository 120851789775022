import React from 'react'

import House from '../Header/House'

import { Box, styled, Typography } from '@mui/material'
import FlatSecond from './FlatSecond';


const MainBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '600px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]:{
        display: 'flex',
      },
    [theme.breakpoints.down('sm')]: {
        marginTop: '-10px',
        display: 'block',
        height: '800px',
        overflow: 'hidden',
       
    }
}))

const TextBox = styled(Box)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    fontFamily: "lato, sans-serif",
    fontSize: '25px',
    wordWrap: 'break-word',
    marginTop: '115px',
    marginLeft: '170px',
    letterSpacing: '2px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]:{
        fontSize: '25px',
        marginTop: '120px',
        marginLeft: '100px',
      },
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        wordWrap: 'break-word',
        padding: '25px',
        marginTop: '10px',
        marginLeft: '10px',
        letterSpacing: '2px',
        fontWeight: '400',
    }
}))


const TextBoxGet = styled(Box)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    width: '45%',
    fontSize: '16px',
    marginTop: '10px',
    wordWrap: 'break-word',
    marginLeft: '170px',
    letterSpacing: '1px',
    lineHeight:'22px',
    fontWeight: '500',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]:{
        width: '70%',
        fontSize: '16px',
        marginLeft: '100px',
        lineHeight:'20px', 
      },
    [theme.breakpoints.down('sm')]: {

        fontSize: '10px',
        marginTop: '-20px',
        width: '320px',
        marginLeft: '35px',
        lineHeight:'15px',  
        fontWeight: '400',
    }
}))


const KartBox = styled(Box)(({ theme }) => ({
    width: '240px',
    height: '70px',
    background: '#F1F3F6',
    marginLeft: '170px',
    marginTop: '55px',
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '100px',
        marginTop: '70px',
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '75px',
        marginTop: '10px',
    }

}))

const KartBoxSec = styled(Box)(({ theme }) => ({
    width: '240px',
    height: '70px',
    background: '#F1F3F6',
    marginLeft: '170px',
    marginTop: '20px',
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '100px',
      
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '75px',
    }

}))


const TypeTextBox = styled(Box)`
    color: #F15A24;
    margin-left: 17px;
    width: 100%;
    padding: 22px;
    font-size: 16px;
  `;

const AndriodIcon = styled(Box)`
    margin-left: 170px;
    margin-top: -56px;
  `;




const ImageNew = styled('img')(({ theme }) => ({
    width: '230px',
    height: '460px',
    marginRight: '200px',
    marginTop: '75px',
    [theme.breakpoints.down('lg')]:{
        marginRight: '90px',
        marginTop: '75px',
      },
    [theme.breakpoints.down('sm')]: {
        width: '200px',
        height: '400px',
        marginLeft: '95px',
        marginTop: '30px',
    }
}))

const TairMainBox = styled(Box)(({theme})=>({
    display: 'flex',
    marginLeft: '340px',
    marginTop: '100px',
    [theme.breakpoints.down('lg')]:{
        display: 'flex',
        marginLeft: '75px',
        marginTop: '60px',
        marginBottom: '80px',
      },
    [theme.breakpoints.down('sm')]:{
        display: 'block',
        marginLeft: '25px',
        marginTop: '30px'
    }
}))



const ImageFont = styled('img')(({theme})=> ({
    width: '770px',
    height: '250px',
    [theme.breakpoints.down('lg')]:{
        width: '450px',
        height: '350px',
      },
    [theme.breakpoints.down('sm')]: {
        width: '300px',
        height: '180px',
        marginLeft: '18px',
    }
}))

const TextFildLast = styled(Typography)(({theme})=> ({
        fontFamily: "'Lato', sans-serif",
        marginTop: '10px',
        width: '50%',
        marginLeft: '30px',
        color: 'grey',
        fontSize: '16px',
        lineHeight: '28px',
        
        [theme.breakpoints.down('lg')]: {
            width: '100%',
          },
        [theme.breakpoints.down('sm')]:{
            fontSize: '15px',
            lineHeight: '20px',
            marginLeft: '80px',
            width: '50%',
        } 
    }))



const JumbText = styled(Typography)(({theme})=> ({
    fontFamily: "'Lato', sans-serif",
    fontSize: '20px',
    marginLeft: '30px',
    marginTop: '25px',
    fontWeight: '600',
    color: '#F15A24',
    [theme.breakpoints.down('lg')]: {
        marginTop: '20px',
        marginLeft: '50px',
      },
    [theme.breakpoints.down('sm')]: {
        marginTop: '20px',
        fontSize: '18px',
        marginLeft: '70px'
    }
}))

const ImageSend = styled('img')({
    width:'40px',
    height:'40px',
  })




const DeadBattery = () => {
    return (
        <>
            <House />

            <MainBox>
                <Box>
                    <TextBox>Vehicle would begin? We can help.</TextBox>
                    <TextBoxGet>Regardless of where you are, getting going again is simple with Roadsiderx. Our specialist co-ops find you quick with GPS route, and will be naturally dispatched when you demand administration.</TextBoxGet>

                    <KartBox>
                        <TypeTextBox>Download Now </TypeTextBox>
                        <AndriodIcon>
                        <ImageSend src='Image/PlayStore.png'  style={{ fontSize: '40px', color:'#555555', marginTop:'4px' }} />
                        </AndriodIcon>
                    </KartBox>

                    <KartBoxSec>
                        <TypeTextBox>Download Now </TypeTextBox>
                        <AndriodIcon>
                        <ImageSend src="Image/Andriod.png" alt="" style={{fontSize: '40px', color: '#3DDC84'}}/>
                        </AndriodIcon>
                    </KartBoxSec>

                </Box>
                <ImageNew src="Image/Image-1.png" alt="" />
            </MainBox>

            <Box>
                <TairMainBox>
                    <ImageFont src="https://alphatowtruckservices.com/wp-content/uploads/2020/10/JO-How-to-safely-b25C879-808x450-1.jpg" alt="" />
                    <Box>
                        <JumbText>Jump Start Car or Truck</JumbText>
                        <TextFildLast>
                            If your car or truck won’t turn on or you run out of gas on the highway, a technician can provide immediate service.
                            If your car or truck won’t turn on or you run out of gas on the highway, a technician can provide immediate service.
                        </TextFildLast>
                    </Box>
                </TairMainBox>
            </Box>

            <FlatSecond />
        </>
    )
}

export default DeadBattery
