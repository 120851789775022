import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import House from '../Header/House'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import axios from 'axios'
import swal from 'sweetalert'


const FromBox = styled(Box)(({ theme }) => ({
    width: '60%',
    marginLeft: '550px',
    marginTop: '35px',
    marginBottom: '60px',
    [theme.breakpoints.down('lg')]: {
        width: '65%',
        marginLeft: '350px',
        marginTop: '35px',
        marginBottom: '60px',
        overflow: 'hidden',
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: '0',
        marginTop: '10px',
        marginBottom: '20px',
    }
}))



const TextCreate = styled(Typography)(({ theme }) => ({
    marginLeft: '130px',
    padding: '15px',
    color: 'grey',
    fontSize: '22px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '65px',
        padding: '15px',
        color: 'grey',
        fontSize: '22px',
        fontWeight: '600',
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '100px',
        marginTop: '-20',
    }
}))

const RequiredFild = styled(Typography)`
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-left: 15px;
    margin-top: -5px;
`

const BootomSing = styled(Box)(({theme})=> ({
    marginTop: '10px',
    marginLeft: '35px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '10px',
        marginLeft: '35px',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '0px',
        marginLeft: '0px',
    }
}))


const Register = () => {
    const navigate = useNavigate()
    const [registerInput, setRegisterInput] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        mobile_number: '',

    })

    const [formErrors, setformErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);

    const handleInput = (e) => {
        e.persist();
        setRegisterInput({ ...registerInput, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {

        }
    }, [formErrors])


    const validate = (values) => {
        const errors = {};
        if (!values.first_name) {
            errors.first_name = 'First Name is required!';
        }
        if (!values.last_name) {
            errors.last_name = 'Last Name is required!';
        }
        if (!values.email) {
            errors.email = 'Email is required!';
        }
        if (!values.password) {
            errors.password = 'Password is required!';
        }
        if (!values.mobile_number) {
            errors.mobile_number = 'Mobile Number is required!';
        }
        return errors;

    }

    async function registerSubmit(e) {
        e.preventDefault();
        setformErrors(validate(registerInput));
        setIsSubmit(true);
        const data = {
            first_name: registerInput.first_name,
            last_name: registerInput.last_name,
            email: registerInput.email,
            password: registerInput.password,
            mobile_number: registerInput.mobile_number,
        }
        await axios.post(`create-user`, data).then(res => {
            if (res.data.status === '200') {
                setRegisterInput(res.data)
                swal('Thank you for Register Success')
                navigate('/login')
            }
            else {
                swal('Email OR Phone Already Occupied')
            }
        })
    }

    return (
        <>
            <House />
            <FromBox>
                <TextCreate>Create Account</TextCreate>
                <form >


                    <div className="form-group col-md-6">
                        <label for="inputEmail4">First Name</label>
                        <input type="text" name='first_name' onChange={handleInput} value={registerInput.first_name} className="form-control" id="inputEmail4" />

                    </div>
                    <RequiredFild>{formErrors.first_name}</RequiredFild>

                    <div className="form-group col-md-6">
                        <label for="inputEmail4">Last Name</label>
                        <input type="text" name='last_name' onChange={handleInput} value={registerInput.last_name} className="form-control" id="inputEmail4" />

                    </div>

                    <RequiredFild>{formErrors.last_name}</RequiredFild>

                    <div className="form-group col-md-6">
                        <label for="inputEmail4">Email</label>
                        <input type="email" name='email' onChange={handleInput} value={registerInput.email} className="form-control" id="inputEmail4" />

                    </div>

                    <RequiredFild>{formErrors.email}</RequiredFild>

                    <div className="form-group col-md-6">
                        <label for="inputPassword4">Password</label>
                        <input type="password" name='password' onChange={handleInput} value={registerInput.password} className="form-control" id="inputPassword4" />

                    </div>

                    <RequiredFild>{formErrors.password}</RequiredFild>

                    <div className="form-group col-md-6">
                        <label for="inputPassword4">Phone Number</label>
                        <input type="number" name='mobile_number' onChange={handleInput} value={registerInput.mobile_number} className="form-control" id="inputPassword4" />

                    </div>
                    <RequiredFild>{formErrors.mobile_number}</RequiredFild>
                    <BootomSing>
                        <Link  type="submit" onClick={registerSubmit} className="btn btn-secondary col-md-5">Sign in</Link>
                    </BootomSing>
                </form>
            </FromBox>
        </>
    )
}

export default Register
