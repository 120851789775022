import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
    width: '270px',
    height: '200px',
    marginLeft: '-5px',
    marginTop: '-310px',
    borderRadius:'10px'
};




const center = {
    lat: -3.745,
    lng: -38.523
};

function MyComponent() {

        const {isLoaded} = useJsApiLoader({
            id: 'google-map-script',
        googleMapsApiKey: "AIzaSyABSuiQjEPlVZaaNZNWH1cf21szLDVvFAE"
  })

        const [map, setMap] = React.useState(null)

        const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
  }, [])

        const onUnmount = React.useCallback(function callback(map) {
            setMap(null)
        }, [])

        return isLoaded ? (
            
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            { /* Child components, such as markers, info windows, etc. */}
            <></>
        </GoogleMap>
        ) : <></>


}

export default React.memo(MyComponent)