import React from 'react'
import { Link } from 'react-router-dom'
import { Box, styled } from '@mui/material'


const MainBox = styled(Box)(({theme})=> ({
    marginLeft: '330px',
 
    [theme.breakpoints.down('lg')]: {
        marginLeft: '20px',
        overflow: 'hidden',
    }
}))
    

const ImageFont = styled('img')(({theme})=> ({
    width: '210px', 
    height: '50px', 
    marginLeft: '145px', 
    cursor: 'pointer',  
    [theme.breakpoints.down('lg')]: {
        width: '210px', 
        height: '50px', 
        marginLeft: '25px', 
        cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
        width: '210px', 
        height: '50px',
        marginLeft: '30px',
        //overflow: 'hidden',
    }
}))



const Header = () => {
    return (
        <>
            <nav className="navbar sticky-top  navbar-expand-lg navbar-light bg-light">
                <Link to='/'><ImageFont src="Image/RX12.png" alt="" /></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                <MainBox>


                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">

                            <li className="nav-item dropdown">
                                <Link className="nav-link" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{fontSize: '14px'}}>
                                    SERVICES
                                </Link>
                                <div className="dropdown-menu " style={{fontSize: '12px'}} aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/flattire">FLAT TIRE</Link>
                                    <Link className="dropdown-item" to="/towtruck">TOW TRUCK</Link>
                                    <Link className="dropdown-item" to="/deadbattery">DEAD BATTERY</Link>
                                    <Link className="dropdown-item" to="/outoffuel">OUT OF FUEL</Link>
                                    <Link className="dropdown-item" to="/mobilemechaic">MOBILE MECHANIC</Link>
                                    <Link className="dropdown-item" to="/lockout">LOCK OUT</Link>
                                </div>
                            </li>
                            <li className="nav-item dropdown ml-2">
                                <Link className="nav-link " to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{fontSize: '14px'}}>
                                    LOCATIONS
                                </Link>
                                <div className="dropdown-menu" style={{fontSize: '13px'}}  aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/location">ALL LOCATIONS</Link>
                                </div>
                            </li>
            
                            <li className="nav-item ml-2">
                                <Link className="nav-link " to="/about" style={{fontSize: '14px'}}>ABOUT</Link>
                            </li>
                            <li className="nav-item ml-2">
                                <Link className="nav-link " to="/industrysolutions" style={{fontSize: '15px'}}>INDUSTRY SOLUTIONS</Link>
                            </li>
                            <li className="nav-item dropdown ml-2">
                                <Link className="nav-link" to="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{fontSize: '14px'}}>
                                    SERVICE PROVIDERS
                                </Link>
                                <div className="dropdown-menu" style={{fontSize: '13px'}}  aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/whotheare">WHO THEY ARE</Link>
                                    <Link className="dropdown-item" to="/becomeservice">BECOME A SERVICE PROVIDER</Link>
                            
                                </div>
                            </li>
                            <li className="nav-item ml-2">
                                <Link className="nav-link " to="/contactpage" style={{fontSize: '14px'}}>CONTACT</Link>
                            </li>
                        </ul>

                    </div>
                </MainBox>
            </nav>
          
        </>
    )
}

export default Header
