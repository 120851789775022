import React,{useEffect, useState} from 'react'
import EditIcon from '@mui/icons-material/Edit';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestoreIcon from '@mui/icons-material/Restore';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import { Box, styled, Typography } from '@mui/material'
import { Link } from 'react-router-dom';





const MainBox = styled(Box)(({theme})=>({
    display : 'flex',
    background: '#F3F7F8',
    [theme.breakpoints.down('lg')]:{
        display : 'flex',
        background: '#F3F7F8',
    },
    [theme.breakpoints.down('sm')]:{
        display:'block',
        marginTop:'-55px',
        height: '100%',
    }
}))


const ProfileBox = styled(Box)(({theme})=>({
    width: '325px',
    height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '165px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'35px',       
    }
}))



const ImageBox = styled(Box)(({theme})=>({
    marginLeft: '105px',
    marginTop: '50px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '75px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'105px',
        paddingTop:'30px',
    }
}))

const NameText = styled(Typography)`
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const NumberText = styled(Typography)`
    text-align: center;
    margin-top: 3px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const MainLogoutBox = styled(Box)`
  display: flex;
`

const LogotText = styled(Typography)`
  margin-left: 5px;
  margin-top: 14px;
  color: #F15A24;
`

const MainLogoutBoxSec = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]: {

    }
}))


const IconBoxFr= styled(Box)(({ theme }) => ({
    color: '#F15A24',
    marginTop: '15px',
    marginLeft: '120px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '125px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'120px'
    }

}))

const IconBoxSec = styled(Box)(({ theme }) => ({
    color: '#F15A24',
    marginTop: '2px',
    marginLeft: '75px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '93px',
        marginTop: '0px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'95px'
    }
}))


const LogotTextSec = styled(Typography)(({ theme }) => ({
    marginLeft: '25px',
    marginTop: '2px',
    color: '#F15A24',
    [theme.breakpoints.down('lg')]: {
        marginTop: '0px',
    }
}))

const ProfileBoxSec = styled(Box)(({theme})=>({
    width: '825px',
    //height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '53px',
    [theme.breakpoints.down('lg')]:{
        width: '565px',
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '325px',
        marginLeft: '35px',
        height: '480px',
        // boxShadow: '0 2px 4px 0 rgb(0 0 0 / 0%)',
        // background: '#F3F7F8',
    }
}))

const AddVehicle = styled(Typography)(({theme})=>({
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: "'Lato', sans-serif",
    textAlign: 'center',
    marginTop: '65px',
    [theme.breakpoints.down('lg')]:{
        fontSize: '18px',
        fontWeight: '600',
        fontFamily: "'Lato', sans-serif",
        textAlign: 'center',
        marginTop: '65px',
    },
    [theme.breakpoints.down('sm')]:{
        paddingTop:'55px'
    }
}))


const FileNameBox = styled(Box)(({theme})=> ({
    display: 'flex',
    margin: '50px',
    [theme.breakpoints.down('lg')]:{
        display: 'flex',
        margin: '50px',
    },
    [theme.breakpoints.down('sm')]:{
        margin:'10px'
    }
}))


const FileNameBoxSec = styled(Box)(({theme})=> ({
    display: 'flex',
    margin: '50px',
    marginTop: '-40px',
    [theme.breakpoints.down('lg')]:{
        display: 'flex',
        margin: '50px',
        marginTop: '-40px',
    },
    [theme.breakpoints.down('sm')]:{
        margin: '10px',
    }
}))


const TextBox = styled(Box)(({theme})=>({
    width: '85%',
    marginLeft: '66px',
    marginTop: '-16px',
    [theme.breakpoints.down('lg')]:{
        marginLeft:'65px',
        width: '77%',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'25px',
        marginTop:'20px',
        width: '85%',
    }
}))



const NextBox = styled(Box)(({theme})=> ({
    width: '140px',
    height: '30px',
    background: '#F15A24',
    color: 'white',
    borderRadius: '9px',
    cursor: 'pointer',
    marginLeft: '355px',
    marginTop: '37px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '215px',
        marginTop: '65px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'100px',
        marginTop:'45px',
        
    }
}))


const NextType = styled(Typography)`
    padding: 3px;
    margin-left: 34px;
`

const NameTextTh = styled(Typography)`
    text-align: center;
    margin: 4px;
    margin-top: -5px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`




const UploadAddVehicle = () => {

    const search = window.location.search;
    const params = new URLSearchParams(search)
    let vtype = params.get("vtype");
    let servtype = params.get("servtype");
    let subsurvice = params.get("subsurvice");
    let subservname = params.get("subservname");
    let image = params.get("image");
    let price = params.get("price");
    const userid =  localStorage.getItem('userid');
    const [storeImage, setStoreImage] = useState([])

    const [userName, setUserName] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        profile_photo:''
    })

    const ProfileImageShow = storeImage.map(item => item.imgcro)
       
    const userSetData = async () => {
       const response = await fetch(`https://demo.towbooker.com/api/viewUserbyid?userid=${userid}`)
        const data = await response.json();
        //console.log(data);
       
       setUserName(data.data)
       // localStorage.setItem('username', res.data.message);
        // console.log(data.data) 
         
    }

    useEffect(() => {
        userSetData();
    }, [])


    
    return (
        <>
            <MainBox>
            <ProfileBox>
                    <ImageBox>
                        <div className="flex-shrink-0">
                            <img src={ProfileImageShow}
                                alt="Generic placeholder image" className="img-fluid"
                                style={{ width: '110px', borderRadius: '50%' }} />
                        </div>
                    </ImageBox>
            
                        <NameText>{userName.first_name}</NameText>
                        <NameTextTh>{userName.last_name}</NameTextTh>
                   
                    <NumberText>{userName.mobile_number}</NumberText>
                    <NumberText>{userName.email}</NumberText>
                    <MainLogoutBox>
                        <IconBoxFr>
                            <EditIcon style={{ fontSize: '22px' }} />
                        </IconBoxFr>

                        <LogotText>Edit</LogotText>
                    </MainLogoutBox>
                    <hr />
                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <AccountCircleIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
                 
                        <LogotTextSec>Profile</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <AccessTimeIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
                  
                        <Link to='/booking' style={{ textDecoration: 'none' }} >
                            <LogotTextSec>Bookings</LogotTextSec>
                        </Link>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <PaymentIcon style={{fontSize: '22px' }} />
                        </IconBoxSec>
              
                        <LogotTextSec>Payments</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <RestoreIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
               
                        <LogotTextSec>Transactions</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <StarBorderPurple500Icon style={{  fontSize: '22px' }} />
                        </IconBoxSec>
              
                        <LogotTextSec>Review</LogotTextSec>
                    </MainLogoutBoxSec>

                </ProfileBox>

                <ProfileBoxSec>
                    <AddVehicle> Add Vehicle info</AddVehicle>
                    <FileNameBox>
                        <input className="form-control m-3" type="file" id="formFile" />
                        <input class="form-control m-3" type="file" id="formFile" />
                    </FileNameBox>

                    <FileNameBoxSec>
                        <input class="form-control m-3" type="file" id="formFile" />
                        <input class="form-control m-3" type="file" id="formFile" />
                    </FileNameBoxSec>
                    <TextBox>
                        <div className="form-group ">
                            <textarea className="form-control" name='message' required autoComplete='off' id="exampleFormControlTextarea1" rows="3" placeholder='Enter Vehicle Description'></textarea>
                        </div>
                    </TextBox>

                    <NextBox>
                        <Link to={`/comfirmbooking?vtype=${vtype}&&servtype=${servtype}&&subsurvice=${subsurvice}&&subservname=${subservname}&&image=${image}&&price=${price}`} style={{textDecoration: 'none'}} >
                            <NextType style={{color:'white', }}>Continue</NextType>
                        </Link>

                    </NextBox>
                </ProfileBoxSec>

            </MainBox>
        </>
    )
}

export default UploadAddVehicle
