import Home from './Home'
import React from 'react'
import Header from '../../Header/Header'

import SecondHome from './SecondHome'
import Third from './Third'
 
import Five from './Five'


const HomeTwo = () => {
  return (
    <>
        <Header/>
        <Home/>
        <SecondHome/>
        <Third/>
        <Five/>
        
    </>
  )
}

export default HomeTwo
