import './App.css';


import Navbar from './Header/Navbar';


import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Login from './Components/Login'
import Register from './Components/Register';
import Forget from './Components/Forget';
import Becom from './InsidePage/Becom';
import Flat from './InsidePage/Flat'
import TowTruck from './InsidePage/TowTruck';
import DeadBattery from './InsidePage/DeadBattery';
import OutFull from './InsidePage/OutFull';
import Mobile from './InsidePage/Mobile';
import LockOut from './InsidePage/LockOut';
import TripIns from './InsidePage/TripIns';
import NonEmergancy from './InsidePage/NonEmergancy';
import Location from './InsidePage/Location';
import HomeTwo from './Components/Home/HomeTwo';
import Footer from './Header/Footer'
import About from './InsidePage/About';
import IndustryPage from './InsidePage/IndustryPage';
import ContactPage from './InsidePage/ContactPage';
import WhoTheyAre from './InsidePage/WhoTheyAre';
import BecomeService from './InsidePage/BecomeService';
import TermsAndConditions from './InsidePage/TermsAndConditions';
import Privecy from './InsidePage/Privecy';
import Bussiness from './InsidePage/Bussiness';
import Insurance from './InsidePage/Insurance';
import DocumentDetails from './InsidePage/DocumentDetails';
import Profile from './Dashboard/Profile';
import axios from 'axios';
import Booking from './Dashboard/Booking';
import React from 'react';
import FlatTire from './QuestionsBooking/FlatTire';
import FlatTireOption from './QuestionsBooking/FlatTireOption';
import SeduleBooking from './QuestionsBooking/SeduleBooking';
import UploadAddVehicle from './QuestionsBooking/UploadAddVehicle';
import ComfirmBookin from './QuestionsBooking/ComfirmBookin';
import SubFooter from './Header/SubFooter';


axios.defaults.baseURL = 'https://demo.towbooker.com/api/';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';

function App() {



  return (
    <React.Fragment>

      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<HomeTwo />} />
          <Route path="/login" element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/forget' element={<Forget />} />
          <Route path='/become' element={<Becom />} />
          <Route path='/flattire' element={<Flat />} />
          <Route path='/towtruck' element={<TowTruck />} />
          <Route path='/deadbattery' element={<DeadBattery />} />
          <Route path='/outoffuel' element={<OutFull />} />
          <Route path='/mobilemechaic' element={<Mobile />} />
          <Route path='/lockout' element={<LockOut />} />
          <Route path='/trip' element={<TripIns />} />
          <Route path='/nonemer' element={<NonEmergancy />} />
          <Route path='/location' element={<Location />} />
          <Route path='/about' element={<About />} />
          <Route path='/industrysolutions' element={<IndustryPage />} />
          <Route path='/contactpage' element={<ContactPage />} />
          <Route path='/whotheare' element={<WhoTheyAre />} />
          <Route path='/becomeservice' element={<BecomeService />} />
          <Route path='/treamcondtion' element={<TermsAndConditions />} />
          <Route path='/privecy' element={<Privecy />} />
          <Route path='/business' element={<Bussiness />} />
          <Route path='/insurance' element={<Insurance />} />
          <Route path='/document' element={<DocumentDetails />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/booking' element={<Booking />} />
          <Route path='/flattireoption' element={<FlatTireOption />} />
          <Route path='/flattirebooking' element={<FlatTire />} />
          <Route path='/sedulebooking' element={<SeduleBooking />} />
          <Route path='/vehicleinfo' element={<UploadAddVehicle />} />
          <Route path='/comfirmbooking' element={<ComfirmBookin />} />
        </Routes>
        <SubFooter />
        <Footer />
      </BrowserRouter>
    </React.Fragment>



  );
}

export default App;
