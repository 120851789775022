import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';


const MainBox = styled(Box)(({theme})=>({
    width: '100%',
    height: '480px',
    background: '#364347',
    [theme.breakpoints.down('lg')]:{
        width: '100%',
        height: '480px',
        background: '#364347',
    },
    [theme.breakpoints.down('sm')]:{
        height:'850px',
    }
}))



const SecondBox = styled(Box)(({theme})=>({
    display: 'flex',
    [theme.breakpoints.down('lg')]:{
        display:'flex',
    },
    [theme.breakpoints.down('sm')]:{
        display:'block',
    }
}))



const SecondBoxSec = styled(Box)`
    display: flex;
`

const ImageMainBox = styled(Box)(({theme})=>({
    width: '600px',
    height: '200px',
    // border: 2px solid red;
    marginLeft: '20px',
    marginTop: '85px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '00px',
        marginTop: '80px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '380px',
        height: '200px',
        marginLeft: '00px',
        marginTop: '80px',
        //border: '2px solid red',
    }
}))



const Image = styled('img')(({theme})=>({
    width: '310px',
    height: '70px',
    marginLeft: '130px',
    [theme.breakpoints.down('lg')]:{
        width: '200px',
        height: '45px',
        marginLeft: '60px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft: '85px',
        marginTop:'40px',
    }
}))


const TextFildBox = styled(Box)(({theme})=>({
    width: '50%',
    marginLeft: '140px',
    marginTop: '22px',
    [theme.breakpoints.down('lg')]:{
        width: '70%',
        marginLeft: '65px',
        marginTop: '18px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '80%',
        marginLeft: '50px',
    }
}))



const TextFild = styled(Typography)`
    font-family: Lato,sans-serif;
    color: #fff;
    font-size: 14px;
    text-align: left;
    font-weight: 350;
    line-height: 23px;
`

const CompanyBox = styled(Box)(({theme})=>({
    width: '300px',
    height: '200px',
    //border: 2px solid red;
    marginTop: '80px',
    [theme.breakpoints.down('lg')]:{
        width: '300px',
        height: '200px',
        //border: 2px solid red;
        marginTop: '80px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '100px',
        height: '200px',
        marginTop: '50px',
        marginLeft: '55px',
        //border: '2px solid red',
    }
}))



const ComapText = styled(Typography)`
    margin-bottom: 20px;
    font-family: Lato,sans-serif;
    color: #fff;
    font-size: 14px;
    text-align: left;
    text-transform: uppercase;
`

const AboutText = styled(Typography)`
    display: block;
    padding-top: 7px;
    padding-bottom: 7px;
    font-family: Lato,sans-serif;
    color: #FFF;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: left;
`

const ServiceBox = styled(Box)(({theme})=> ({
    width: '300px',
    height: '200px',
    //border: 2px solid red;
    marginTop: '80px',
    [theme.breakpoints.down('lg')]:{
        width: '300px',
        height: '200px',
        //border: 2px solid red;
        marginTop: '80px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '150px',
        height: '200px',
        marginTop: '-200px',
        marginLeft: '190px',
        //border: '2px solid red',
    }

}))


const ConatactBox = styled(Box)(({theme})=> ({
    width: '300px',
    height: '200px',
    //border: 2px solid red;
    marginTop: '80px',
    [theme.breakpoints.down('lg')]:{
        width: '300px',
        height: '200px',
        //border: 2px solid red;
        marginTop: '80px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '150px',
        height: '200px',
        marginLeft:'140px',
        //border: '2px solid red',
        marginTop:'5px',
    }
}))

const SubBox = styled(Box)(({theme})=> ({
    marginTop: '25px',
    marginLeft: '555px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '310px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft: '3px',
    }
}))



const SubText = styled(Typography)(({theme})=>({
    display: 'inline-block',
    marginRight: '10px',
    marginLeft: '10px',
    fontFamily: "Lato,sans-serif",
    color: '#c2c2c2',
    fontSize: '12px',
    lineHeight: '40px',
    fontWeight: '400',
    textTransform: 'uppercase',
    [theme.breakpoints.down('lg')]:{
        display: 'inline-block',
        marginRight: '10px',
        marginLeft: '10px',
        fontFamily: "Lato,sans-serif",
        color: '#c2c2c2',
        fontSize: '12px',
        lineHeight: '40px',
        fontWeight: '400',
        textTransform: 'uppercase',
    },
    [theme.breakpoints.down('sm')]:{
        marginRight: '0px',
        marginLeft: '10px',
    }
}))



const Footer = () => {

    return (
        <>
            <MainBox>
                <SecondBox>
                    <ImageMainBox>
                        <Image src="Image/RX13.png" alt="" />
                        <TextFildBox>
                            <TextFild>Towbooker Roadsider Assistance Service™,
                                The Only Global Digital Software
                                Taxi Booking Platform, Continues To
                                Reimagine The Booking Process
                                Industry To The Benefit Of Global Brands,
                                Drivers, And
                                Consumers.</TextFild>
                        </TextFildBox>
                    </ImageMainBox>

                    <CompanyBox>
                        <ComapText>COMPANY</ComapText>
                        <Box>
                            <Link  to='/about'>
                            <AboutText >About Us</AboutText>
                            </Link>
                            <AboutText>Services</AboutText>
                            <Link to='/booking'>
                                <AboutText>Booking</AboutText>
                            </Link>
                            <Link to='/contactpage'>
                                <AboutText>Contact Us</AboutText>
                            </Link>
                        </Box>
                    </CompanyBox>
                    <ServiceBox>
                        <ComapText>SERVICE PROVIDERS</ComapText>
                        <Box>
                            <AboutText>Work With Us</AboutText>
                            <Link to='/register'>
                                <AboutText>Signup</AboutText>
                            </Link>
                            <Link to='/become'>
                                <AboutText>Servicer Login</AboutText>
                            </Link>

                        </Box>
                    </ServiceBox>
                    <ConatactBox>
                        <ComapText>CONNECT WITH US</ComapText>
                        <Box>
                            <a href='https://www.facebook.com/'  target="_blank" rel="noopener noreferrer" role='button'>
                                <AboutText>Facebook</AboutText>
                            </a>
                            <a href='https://twitter.com/i/flow/login' target="_blank" rel="noopener noreferrer" >
                                <AboutText>Twitter</AboutText>
                            </a>

                            <a href='https://www.instagram.com/' target="_blank" rel="noopener noreferrer">
                                <AboutText>Instagram</AboutText>
                            </a>

                            <a href='https://www.linkedin.com/' target="_blank" rel="noopener noreferrer">
                                <AboutText>LinkedIn</AboutText>
                            </a>
                        </Box>
                    </ConatactBox>

                </SecondBox>
                <section className='text-center mt-5'>
                    <a outline floating className='m-3' href='https://www.facebook.com/' target="_blank" rel="noopener noreferrer" role='button'>
                        <FacebookIcon style={{ fontSize: '40px', color: '#F15A24' }} />
                    </a>

                    <a outline floating className='m-3' href='https://twitter.com/i/flow/login' target="_blank" rel="noopener noreferrer" role='button'>
                        <TwitterIcon style={{ fontSize: '40px', color: '#F15A24' }} />
                    </a>


                    <a outline color="light" floating className='m-3' href='https://www.instagram.com/' target="_blank" rel="noopener noreferrer" role='button'>
                        <InstagramIcon style={{ fontSize: '40px', color: '#F15A24' }} />
                    </a>
                    <a outline color="light" floating className='m-3' href='https://www.linkedin.com/' target="_blank" rel="noopener noreferrer" role='button'>
                        <LinkedInIcon style={{ fontSize: '40px', color: '#F15A24' }} />
                    </a>
                </section>
                <SecondBoxSec>
                    <SubBox>
                        <Link to='#'>
                            <SubText>2022 © TOWBOOKER.</SubText>
                        </Link>
                        <Link to='/treamcondtion'>
                            <SubText>TERMS & CONDITION</SubText>
                        </Link>
                        <Link to='#'>
                            <SubText>PRIVACY POLICY</SubText>
                        </Link>
                    </SubBox>
                </SecondBoxSec>
            </MainBox>

        </>
    )
}

export default Footer
