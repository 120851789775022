import React from 'react'

import {styled, Box, Typography } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public';
import MapIcon from '@mui/icons-material/Map';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import CampaignIcon from '@mui/icons-material/Campaign';



const MainBox = styled(Box)(({theme})=> ({
    background: '#F1F3F6',
    padding: '15px',
    height: '390px',
    overflow:'hidden',
    [theme.breakpoints.down('lg')]: {
        height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
        background: '#fff',
        padding: '25px',
        marginLeft: '13px',
        height: '800px',
    }
}))


const Welcometype = styled(Typography)(({theme})=> ({
    fontFamily: "'Lato', sans-serif",
    fontSize: '25px',
    fontWeight: '600',
    color: '#F15A24',
    textAlign: 'center',
    letterSpacing: '3px;',
    [theme.breakpoints.down('lg')]: {
        fontSize: '25px',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '-130px',
        fontSize: '18px',
        fontWeight: '600',
        color: '#29398d',
        textAlign: 'center',
        letterSpacing: '0px;',
    }
}))



const MachText = styled(Typography)(({theme})=> ({
    fontFamily: "'Lato', sans-serif",
    color: 'black',
    marginTop: '5px',
    fontSize: '14px',
    fontWeight: '600',
    textAlign: 'center',
    lineHeight: '28px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '14px',
        fontWeight: '600',
    }
}))



const SecBox = styled(Box)(({theme})=> ({
    marginTop: '58px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '45px',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '100px',
    }
}))



const ThirdBox = styled(Box)(({theme})=> ({
    display: 'flex',
    padding: '50px',
    marginLeft: '30px',
    marginTop:'-30px',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
        padding: '50px',
        marginLeft: '30px',
        marginTop:'0px',
    },
    [theme.breakpoints.down('sm')]: {
        display: 'block',
        padding: '50px',
        marginLeft: '30px',
        marginTop:'0px',
    }
}))


const GspBox = styled(Box)(({theme})=>({
    width:'100%',
    marginTop: '5px',
    marginLeft: '90px',
    color: '#000000',
    minHeight: '50px',
    marginBottom: '15px',
    fontSize: '13px',
    lineHeight: '24px',
    fontWeight: '600',
    fontFamily: "'Lato', sans-serif",
    [theme.breakpoints.down('lg')]: {
        width:'100%',
        marginTop: '25px',
        marginLeft: '0px',
        color: '#000000',
        minHeight: '50px',
        marginBottom: '15px',
        fontSize: '13px',
        lineHeight: '24px',
        fontWeight: '500',
        fontFamily: "'Lato', sans-serif",
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
        marginLeft: '-10px',
        color: '#000000',
        fontSize: '12px',
        fontWeight: '600',
        fontFamily: "'Lato', sans-serif",
    }
}))

const GspBoxTwo = styled(Box)(({theme})=>({
    width:'100%',
    marginTop: '5px',
    marginLeft: '68px',
    color: '#000000',
    minHeight: '50px',
    marginBottom: '15px',
    fontSize: '13px',
    lineHeight: '24px',
    fontWeight: '600',
    fontFamily: "'Lato', sans-serif",
    [theme.breakpoints.down('lg')]: {
        width:'100%',
        marginTop: '25px',
        marginLeft: '0px',
        color: '#000000',
        minHeight: '50px',
        marginBottom: '15px',
        fontSize: '13px',
        lineHeight: '24px',
        fontWeight: '500',
        fontFamily: "'Lato', sans-serif",
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
        marginLeft: '5px',
        color: '#000000',
        fontSize: '12px',
        fontWeight: '600',
        fontFamily: "'Lato', sans-serif",
    }
}))




const MainGspBox = styled(Box)(({theme})=>({
    marginTop: '5px',
    marginLeft: '75px',
    color: '#000000',
    minHeight: '50px',
    marginBottom: '15px',
    fontSize: '13px',
    lineHeight: '24px',
    fontWeight: '600',
    fontFamily: "'Lato', sans-serif",
    [theme.breakpoints.down('lg')]: {
        marginTop: '25px',
        marginLeft: '0px',
        color: '#000000',
        minHeight: '50px',
        marginBottom: '15px',
        fontSize: '13px',
        lineHeight: '24px',
        fontWeight: '500',
        fontFamily: "'Lato', sans-serif",
    },
    [theme.breakpoints.down('sm')]: {  
        marginTop: '-15px',
        marginLeft: '-8px',
        padding: '20px',
        color: '#000000',      
        fontSize: '12px',      
        fontWeight: '600',
        fontFamily: "'Lato', sans-serif",
    }
}))
   


const WeUseText = styled(Typography)(({theme})=> ({
    margin: '15px',
    marginTop:'-25px',
    marginLeft:'40px',
    fontSize: '13px',
    lineHeight: '18px',
    color: '#787878',
    fontFamily: "'Lato', sans-serif",
    [theme.breakpoints.down('lg')] :{
        margin: '25px',
        marginTop:'-25px',
        marginLeft:'2px',
        fontSize: '14px',
        lineHeight: '18px',
        color: '#787878',
        fontFamily: "'Lato', sans-serif",
    },
    [theme.breakpoints.down('sm')]: {
        width: '320px',
        marginLeft: '-70px',
        marginTop: '-35px',
    }
}))


const PublicBoxIcon = styled(Box)(({theme})=> ({
     color: '#F15A24', 
     marginLeft: '10rem',
     [theme.breakpoints.down('lg')]:{
        color: '#F15A24', 
        marginLeft: '4.5rem',
     },
     [theme.breakpoints.down('sm')]: {
        marginLeft: '3.5rem',
        marginTop: '-30px',
     }
}))

const MapBox = styled(Box)(({theme})=> ({
     color: '#F15A24', 
     marginLeft: '8rem',
     [theme.breakpoints.down('lg')]:{
        color: '#F15A24', 
        marginLeft: '3rem',
     },
     [theme.breakpoints.down('sm')]: {
        marginLeft: '3.5rem',
        marginTop: '20px',
     }
}))


const MasageBoxIcon = styled(Box)(({theme})=> ({
     color: '#F15A24', 
     marginLeft: '8rem', 
     [theme.breakpoints.down('lg')]: {
        color: '#F15A24', 
        marginLeft: '3.5rem', 
     },
     [theme.breakpoints.down('sm')]: {
        marginLeft: '3.5rem',
        marginTop: '20px',
     }
}))


const CompionIconBox = styled(Box)(({theme})=> ({
     color: '#F15A24', 
     marginLeft: '8rem',
     [theme.breakpoints.down('lg')]: {
      
        color: '#F15A24', 
        marginLeft: '3.5rem',
     
     },
     [theme.breakpoints.down('sm')]: {
        marginLeft: '3.5rem',
        marginTop: '20px',
     }
}))






const Third = () => {
    return (
        <React.Fragment>
        <MainBox>
            <SecBox>
                
                    <Welcometype>Towbooker Services at a Glance</Welcometype>
                    <MachText>The On-Demand Towbooker Assistance App.</MachText>
               
            </SecBox>
            <ThirdBox>
                <Box>
                    <PublicBoxIcon>
                        <PublicIcon style={{fontSize: '2.5rem'}} />
                    </PublicBoxIcon>
                    <GspBox>GPS LOCATION ASSISTANCE</GspBox>

                    <WeUseText>We use the latest GPS and mapping technology to get you help fast. No more trying to figure out where you are and describing your location.
                    </WeUseText>
                </Box>
                <Box>
                    <MapBox>
                        <MapIcon style={{fontSize: '2.5rem'}}  />
                    </MapBox>
                  
                    <MainGspBox>NO MEMBERSHIP ANY</MainGspBox>
                    <WeUseText>Only pay for help when you need it. The app gives you access to our network of vetted providers, ready.
                    </WeUseText>
                </Box>


                <Box>

                    <MasageBoxIcon>
                        <MapsUgcIcon style={{fontSize: '2.5rem'}} />
                    </MasageBoxIcon>
                    
                    <GspBoxTwo>IN-APP COMMUNICATION</GspBoxTwo>
                    <WeUseText>Want to know when your tow truck is finally going to arrive? Now you can talk directly with the person on the way to help you.
                    </WeUseText>
                </Box>


                <Box>

                    <CompionIconBox >
                        <CampaignIcon style={{fontSize: '2.5rem'}}/>
                    </CompionIconBox>
                 
                    <GspBoxTwo>AUTOMATED DISPATCH</GspBoxTwo>
                    <WeUseText>Our app finds the closest provider to you without the hassle of someone calling and haggling a price for you.
                    </WeUseText>
                </Box>
            </ThirdBox>
        </MainBox>
       
        </React.Fragment>
    )
}

export default Third
