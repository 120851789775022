import React from 'react'
import House from '../Header/House'
import { Box, styled, Typography } from '@mui/material'
import { Link } from 'react-router-dom'



const MainBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '600px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    color: '#fff',
    overflow: 'hidden',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]:{
        display: 'flex',
      },
    [theme.breakpoints.down('sm')]: {
        marginTop: '-10px',
        display: 'block',
        height: '680px',
        overflow: 'hidden',
    }
}))

const TextBox = styled(Box)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    width: '80%',
    fontSize: '25px',
    wordWrap: 'break-word',
    marginTop: '250px',
    marginLeft: '170px',
    letterSpacing: '1px',
    fontWeight: '600',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]:{
        fontSize: '25px',
        marginTop: '205px',
        marginLeft: '100px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        wordWrap: 'break-word',
        padding: '25px',
        marginTop: '10px',
        marginLeft: '50px',
        letterSpacing: '1px',
        textAling: 'center',
        fontWeight: '400',
        overflow: 'hidden',
    }
}))

const TextBoxGet = styled(Box)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    width: '56%',
    fontSize: '18px',
    marginTop: '10px',
    wordWrap: 'break-word',
    marginLeft: '170px',
    letterSpacing: '1px',
    fontWeight: '500',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]:{
        width: '80%',
        fontSize: '16px',
        marginLeft: '100px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        marginTop: '-20px',
        wordWrap: 'break-word',
        marginLeft: '30px',
        letterSpacing: '1px',
        fontWeight: '400',
        overflow: 'hidden',
        textAling: 'center',
    }
}))

const FormTextFild = styled(Box)(({theme})=> ({
    background: '#F15A24',
    marginRight: '170px',
    marginTop: '50px',
    width: '400px',
    height: '500px',
    border: '4px solid #F15A24',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        marginRight: '70px',
    },
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        border: '0px solid #29398d',
        marginLeft: '-5px',
        marginTop: '15px',
        overflow: 'hidden',
    }
}))


const FromBox = styled(Box)`
    
    padding: 30px;
    color: white;
    font-weight: 600;
`;

const FuturMAinBox = styled(Box)(({theme})=> ({
    marginBottom: '50px',
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
        display: 'block',
        overflow: 'hidden',
    }

}))


const TheBoxText = styled(Typography)(({theme})=> ({
    color: '#F15A24',
    fontSize: '25px',
    lineHeight: '60px',
    fontWeight: '600',
    textTransform: 'none',
    fontFamily: "'Lato', sans-serif",
    marginLeft: '170px',
    marginTop: '40px',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        fontSize: '25px',
        marginLeft: '100px',
        marginTop: '40px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        textAling: 'center',
        marginLeft: '25px',
        marginTop: '15px',
        overflow: 'hidden',
    }

}))


const BuiltText = styled(Typography)(({theme})=> ({
    width: '80%',
    color: '#454545',
    fontSize: '16px',
    lineHeight: '28px',
    marginLeft: '170px',
    fontFamily: "'Lato', sans-serif",
    fontWeight: '600',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '100px',
    },
    [theme.breakpoints.down('sm')]: {
        textAling: 'center',
        marginLeft: '50px',
        fontSize: '13px',
        overflow: 'hidden',
      
    }
}))



const BuiltTextSec = styled(Typography)(({theme})=> ({
    fontFamily: "'Lato', sans-serif",
    width: '80%',
    color: '#454545',
    fontSize: '16px',
    lineHeight: '28px',
    marginLeft: '190px',
    fontFamily: "'Open Sans', sans-serif",
    fontWeight: '500',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '100px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '15px',
        marginLeft: '65px',
        fontWeight: '500',
        overflow: 'hidden',
    }
}))



const ImageNew = styled('img')(({ theme }) => ({
    width: '230px',
    height: '460px',
    marginRight: '250px',
    marginTop: '110px',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
        width: '360px',
        height: '460px',
        marginRight: '90px',
        marginTop: '140px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      height: '400px',
      marginLeft: '95px',
      marginTop: '50px',
      overflow: 'hidden',
  
    }
  }))

const IndustryPage = () => {
    return (
        <>
            <House />
            <MainBox>
                <Box>
                    <TextBox>What can Towbooker Technology do for you?</TextBox>
                    <TextBoxGet>Towbooker’s patent-pending process helps you deliver roadside assistance with true automation! </TextBoxGet>


                </Box>

                <Box>
                    <FormTextFild>
                        <FromBox>
                            <div className="form-row">
                                <div className="col" >
                                    <label for="inputEmail4">First Name </label>
                                    <input type="text" className="form-control"  />
                                </div>
                                <div className="col">
                                    <label for="inputEmail4">Last Name </label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>

                            <div className="form-group mt-2">
                                <label for="inputAddress">Your Email (required)</label>
                                <input type="email" className="form-control" id="inputAddress"  />
                            </div>

                            <div className="form-group mt-2">
                                <label for="inputAddress">Your Phone Number</label>
                                <input type="number" className="form-control" id="inputAddress"  />
                            </div>

                            <div className="form-group mt-2">
                                <label for="exampleFormControlTextarea1">Your Message</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>

                            <Link type="submit" to='/' className="btn btn-danger">I WANT MORE INFO!</Link>
                        </FromBox>
                    </FormTextFild>
                </Box>
            </MainBox>

            <Box>
                <FuturMAinBox>
                    <Box>
                        <TheBoxText>The Future of Roadside Assistance</TheBoxText>
                        <BuiltText>You’ve built a brand, now put the technology behind it to really move it forward!</BuiltText>
                        <BuiltText>Towbooker offers custom packages based on your needs. You can harness the technology we’ve built for your business.</BuiltText>
                        <BuiltText>We offer:</BuiltText>
                        <BuiltText>White label, software license, and co-branding</BuiltText>
                        <BuiltTextSec>Automated dispatch via proprietary dispatching system</BuiltTextSec>
                        <BuiltTextSec>In-app communication</BuiltTextSec>
                        <BuiltTextSec>Vetted & insured providers</BuiltTextSec>
                        <BuiltTextSec>GPS tracking for provider & customer</BuiltTextSec>
                        <BuiltTextSec>Faster response</BuiltTextSec>
                        <BuiltTextSec>Payment processing</BuiltTextSec>

                        <BuiltText>Things you won’t have to deal with:</BuiltText>
                        <BuiltTextSec>Call center dispatch costs</BuiltTextSec>
                        <BuiltTextSec>Manual dispatch- long wait times for customers</BuiltTextSec>
                        <BuiltTextSec>Provider management costs</BuiltTextSec>
                        <BuiltTextSec>Price caps and extra charging</BuiltTextSec>
                        <BuiltTextSec>Accounting costs to pay service network</BuiltTextSec>
                        <BuiltTextSec>Manage customer experience</BuiltTextSec>
                        <BuiltTextSec>Communication with customer/provider</BuiltTextSec>
                    </Box>

                    <ImageNew src="Image/Image-1.png" alt="" />
                </FuturMAinBox>
            </Box>

        </>
    )
}

export default IndustryPage
