import React from 'react'
import House from '../Header/House'

import { Box, styled, Typography } from '@mui/material'
import FlatSecond from './FlatSecond';



const MainBox = styled(Box)(({ theme }) => ({
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
  backgroundRepeat: 'no-repeat, repeat',
  height: '600px',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  color: '#fff',
  overflow: 'hidden',
  backgroundAttachment: 'fixed',
  [theme.breakpoints.down('lg')]:{
    display: 'flex',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '-10px',
    display: 'block',
    height: '860px',
    overflow: 'hidden',
  }
}))

const TextBox = styled(Box)(({ theme }) => ({
  fontFamily: "'Lato', sans-serif",
  fontSize: '25px',
  wordWrap: 'break-word',
  marginTop: '80px',
  marginLeft: '180px',
  letterSpacing: '2px',
  fontWeight: '600',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]:{
    fontSize: '25px',
    marginTop: '90px',
    marginLeft: '100px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    wordWrap: 'break-word',
    padding: '25px',
    marginTop: '10px',
    marginLeft: '25px',
    letterSpacing: '2px',
    fontWeight: '400',
    overflow: 'hidden',
  }
}))


const TextBoxGet = styled(Box)(({ theme }) => ({
  fontFamily: "'Lato', sans-serif",
  width: '45%',
  fontSize: '16px',
  marginTop: '10px',
  wordWrap: 'break-word',
  marginLeft: '180px',
  letterSpacing: '1px',
  lineHeight:'22px',
  fontWeight: '500',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]:{
    width: '80%',
    fontSize: '16px',
    marginLeft: '100px',
    lineHeight:'20px',  
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    marginTop: '-20px',
    marginLeft: '50px',
    letterSpacing: '1px',
    width: '85%',
    overflow: 'hidden',
    lineHeight:'15px',  
  }
}))

const ImageNew = styled('img')(({ theme }) => ({
  width: '230px',
  height: '460px',
  marginRight: '200px',
  marginTop: '75px',
  [theme.breakpoints.down('lg')]:{
    marginRight: '90px',
    marginTop: '75px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '200px',
    height: '400px',
    marginLeft: '95px',
    marginTop: '30px',

  }
}))


const KartBox = styled(Box)(({ theme }) => ({
  width: '240px',
  height: '70px',
  background: '#F1F3F6',
  marginLeft: '180px',
  marginTop: '30px',
  borderRadius: '8px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '100px',
    marginTop: '50px',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '77px',
    marginTop: '20px',
    overflow: 'hidden',
  }

}))


const TypeTextBox = styled(Box)(({theme})=> ({
  fontFamily: "'Lato', sans-serif",
  color: '#F15A24',
  marginLeft: '17px',
  width: '100%',
  padding: '22px',
  fontSize: '16px',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]:{
    overflow: 'hidden',
  }

}))
 

const AndriodIcon = styled(Box)`
  margin-left: 170px;
  margin-top: -56px;
`;


const TairMainBox = styled(Box)(({theme})=> ({
  display: 'flex',
  marginLeft: '340px',
  marginTop: '100px',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]:{
    display: 'flex',
    marginLeft: '75px',
    marginTop: '60px',
    marginBottom: '80px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    marginLeft: '31px',
    marginTop: '30px',
    marginBottom: '0px',
    overflow: 'hidden',
  }
}))



const ImageFont = styled('img')(({theme})=>({
    width: '570px',
    height: '250px',
    [theme.breakpoints.down('lg')]:{
      width: '450px',
      height: '350px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '290px',
      height: '200px',
      marginLeft:'18px',
      
    }
}))



const TexFild = styled(Typography)(({theme})=> ({
  fontFamily: "'Lato', sans-serif",
    marginTop: '10px',
    width: '50%',
    marginLeft: '30px',
    color: 'grey',
    fontSize: '16px',
    lineHeight: '28px',
    fontFamily: "'Open Sans', sans-serif",
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      width: '80%',
      marginLeft: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginLeft: '9px',
      fontSize: '15px',
      overflow: 'hidden',
    }
}))



const JumbText = styled(Typography)(({theme})=> ({
    color: '#F15A24',
    fontFamily: "'Lato', sans-serif",
    marginTop: '8px',
    fontSize: '20px',
    marginLeft: '30px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]: {
      marginTop: '68px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '12px',
    }
}))

const ImageSend = styled('img')({
  width:'40px',
  height:'40px',
})

  




const Flat = () => {
  return (
    <>
      <House />

      <MainBox>
        <Box>
          <TextBox>Have a punctured tire? We can help.</TextBox>
          <TextBoxGet>Getting a punctured tire can so disappoint. We're  assist you with getting back out and about as fast as could really be expected. We make getting emergency aides simpler than any time in recent memory with our on-request and mechanized process. Essentially select the help you want and our framework will consequently associate you with the specialist co-op that is nearest to you.</TextBoxGet>

          <KartBox>
            <TypeTextBox>Download Now </TypeTextBox>
            <AndriodIcon>
              <ImageSend src='Image/PlayStore.png'  style={{ fontSize: '40px', color:'#555555', marginTop:'4px' }} />
            </AndriodIcon>
          </KartBox>

          <KartBox>
            <TypeTextBox>Download Now </TypeTextBox>
            <AndriodIcon>
            <ImageSend src="Image/Andriod.png" alt="" style={{fontSize: '40px', color: '#3DDC84'}}/>
          
            </AndriodIcon>
          </KartBox>
        </Box>
        <ImageNew src="Image/Image-1.png" alt="" />
      </MainBox>

      <Box>
        <TairMainBox>
          <ImageFont src="https://img.ccjdigital.com/files/base/randallreilly/all/image/2022/06/TranService_air_pressure.62be06a45e863.png?auto=format%2Ccompress&fit=max&q=70&w=1200" alt="" />
          <Box>
            <JumbText>Change Flat Tires & Add Air</JumbText>
            <TexFild>To request help using roadsiderx, simply open the app or click the button above. Type in your location information and the kind of help you need, such as “change my tire.” roadsiderx will then  to nearby roadside assistance partners and will provide you with a “guaranteed not to exceed” maximum service quote. </TexFild>
          </Box>
        </TairMainBox>
      </Box>
      <FlatSecond />
    </>
  )
}

export default Flat
