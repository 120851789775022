import React from 'react'
import House from '../Header/House'
import { Link } from 'react-router-dom'
import { Box, styled, Typography} from '@mui/material'

const MainBox = styled(Box)(({ theme }) => ({
  backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
  backgroundRepeat: 'no-repeat, repeat',
  height: '550px',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  color: '#fff',
  overflow: 'hidden',
  backgroundAttachment: 'fixed',
  [theme.breakpoints.down('lg')]:{
    height:'350px',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '-10px',
    display: 'block',
    height: '230px',
    overflow: 'hidden',
  }
}))

const TextBox = styled(Box)(({ theme }) => ({
  fontFamily: "'Lato', sans-serif",
  fontSize: '25px',
  lineHeight: '1.2',
  marginLeft: '510px',
  marginTop: '190px',
  letterSpacing: '1px',
  fontWeight: '600',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '260px',
    marginTop: '80px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    textAlign: 'center',
    padding: '25px',
    marginTop: '10px',
    marginLeft: '5px',
    letterSpacing: '1px',
    fontWeight: '400',
    overflow: 'hidden',
  }
}))


const TextBoxGet = styled(Box)(({ theme }) => ({
  width: '60%',
  fontFamily: "'Lato', sans-serif",
  fontSize: '15px',
  marginLeft: '310px',
  marginTop: '30px',
  textAlign: 'center',
  letterSpacing: '1px',
  fontWeight: '500',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]:{
    marginLeft: '190px',
    marginTop: '30px',
    textAlign: 'center'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
    marginTop: '-15px',
    width: '70%',
    marginLeft: '55px',
    letterSpacing: '0px',
    fontWeight: '400',
    overflow: 'hidden',
    textAlign: 'center',
  }
}))


const ButtomBox = styled(Box)(({theme})=> ({
  marginTop: '30px',
  marginLeft: '610px',
  [theme.breakpoints.down('lg')]: {
    marginTop: '30px',
    marginLeft: '370px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '55px',
    marginTop: '15px',
  }
}))




const TairMainBox = styled(Box)(({theme})=> ({
  display: 'flex',
  marginLeft: '200px',
  marginBottom: '100px',
  marginTop: '100px',
  [theme.breakpoints.down('lg')]:{
    display: 'flex',
    marginLeft: '100px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    marginLeft: '31px',
    marginTop: '10px',
    marginBottom: '0px',
 
  }
}))


const TairMainSecBox = styled(Box)(({theme})=> ({
  display: 'flex',
  marginLeft: '120px',
  marginBottom: '100px',
  marginTop: '100px',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]:{
    display: 'flex',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    marginLeft: '31px',
    marginTop: '30px',
    marginBottom: '0px',
   
  }
}))



const ImageFont = styled('img')(({theme})=>({
    width: '585px',
    height: '328px',
    [theme.breakpoints.down('lg')]:{
      width: '350px',
      height: '258px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '290px',
      height: '200px',
      marginLeft: '15px',
      marginTop: '15px',
      
    }
}))




const ImageFontSec = styled('img')(({theme})=>({
  width: '585px',
  height: '328px',
  marginRight: '140px',
  marginTop: '-10px',
  [theme.breakpoints.down('lg')]:{
    width: '350px',
    height: '258px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '290px',
    height: '200px',
    marginLeft: '15px',
    marginTop: '15px',
  }
}))



const TexFild = styled(Typography)(({theme})=> ({
    marginTop: '5px',
    width: '60%',
    marginLeft: '80px',
    color: '#454545',
    fontSize: '16px',
    fontFamily: "'Lato', sans-serif",
    [theme.breakpoints.down('lg')]: {
      width: '85%',
      marginLeft: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      textAlign:'center',
      marginLeft: '0px',
      marginTop: '25px',
      fontSize: '15px',
      marginBottom: '5px',
     
    }
}))


const TexFildSc = styled(Typography)(({theme})=> ({
  marginTop: '5px',
  width: '60%',
  marginLeft: '80px',
  color: '#454545',
  fontSize: '16px',
  fontFamily: "'Open Sans', sans-serif",
  [theme.breakpoints.down('lg')]: {
    width: '95%',
    marginLeft: '0px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    textAlign:'center',
    marginLeft: '0px',
    marginTop: '25px',
    fontSize: '15px',
    marginBottom: '5px',
   
  }
}))



const JumbText = styled(Typography)(({theme})=> ({
    color:'#F15A24',
    marginTop: '25px',
    marginLeft: '80px',
    fontSize: '20px',
    lineHeight: '60px',
    fontWeight: '600',
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0px',
      marginLeft: '30px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-30px',
      textAlign: 'center',
      fontSize: '20px',
      lineHeight: '0px',
      marginTop: '40px',
    }
}))


const JumbTextSeco = styled(Typography)(({theme})=> ({
  color:'#F15A24',
  marginTop: '35px',
  marginLeft: '80px',
  fontSize: '20px',
  lineHeight: '60px',
  fontWeight: '600',
  textTransform: 'none',
  [theme.breakpoints.down('lg')]: {
    marginTop: '-15px',
    marginLeft: '0px',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '90px',
    fontSize: '20px',
    lineHeight: '0px',
    marginTop: '10px',
  }
}))

const JumbTextSecoBox = styled(Typography)(({theme})=> ({
  color:'#F15A24',
  marginTop: '25px',
  marginLeft: '80px',
  fontSize: '20px',
  lineHeight: '60px',
  fontWeight: '600',
  textTransform: 'none',
  [theme.breakpoints.down('lg')]: {
    marginTop: '-10px',
    marginLeft: '32px',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '30px',
    fontSize: '20px',
    lineHeight: '0px',
    marginTop: '45px',
  }
}))


const WhoTheyAre = () => {
  return (
    <>
      <House/>

      <MainBox>
        <Box>
          <TextBox>Towbooker Specialist organizations</TextBox>
          <TextBoxGet>Are reviewed and protected, and know a great deal. Our organization is included shops, versatile mechanics, locksmiths, autonomous tow trucks, and other capable individuals that are able to come help you.</TextBoxGet>
         
          <ButtomBox>

          <Link type="submit" to='#' className="btn btn-danger" style={{fontFamily: "'Lato', sans-serif"}}>BECOME A SERVICE PROVIDER</Link>
          </ButtomBox>
        </Box>
      </MainBox>

      <Box>
        <TairMainBox>
          <ImageFont src="https://www.mach1services.com/wp-content/uploads/2018/09/veteransworking.png" alt="" />
          <Box>
            <JumbText>About Our Suppliers</JumbText>
            <TexFild>Towbooker Specialist organizations include our organization of gifted people prepared to assist you with getting back out and about. From shops and tow truck organizations to portable mechanics and free movers, our suppliers are prepared to present to you the best emergency aides experience conceivable.</TexFild>
          </Box>
        </TairMainBox>
      </Box>

      <Box>
        <TairMainSecBox>
          <Box>
            <JumbTextSeco>Veteran Upheld</JumbTextSeco>
            <TexFildSc>Towbooker is veteran claimed and veteran supplier upheld. We trust in giving open doors to all individuals from the tactical who have served respectably. In the event that you are a veteran we'll postpone any onboarding charges to kick you off as a Towbooker Specialist organization.</TexFildSc>
          </Box>
          <ImageFontSec src="https://www.mach1services.com/wp-content/uploads/2018/08/veterans.jpg" alt="" />
        </TairMainSecBox>
      </Box>

      <Box>
        <TairMainBox>
          <ImageFont src="https://www.mach1services.com/wp-content/uploads/2018/09/vetranworking.png" alt="" />
          <Box>
            <JumbTextSecoBox>Wellbeing is Our Objective</JumbTextSecoBox>
            <TexFild>All Towbooker Specialist organizations are reviewed utilizing a seven-point up-and-comer check. We screen broadly, statewide, and even at the province level, to present to you the most confided in suppliers that anyone could hope to find. Our suppliers and safeguarded and prepared in wellbeing rehearses so when you're out and about, they'll know how to help.</TexFild>
          </Box>
        </TairMainBox>
      </Box>

    </>
  )
}

export default WhoTheyAre
