import React from 'react'

import House from '../Header/House'

import { Box, styled } from '@mui/material'
import FlatSecond from './FlatSecond';



const MainBox = styled(Box)(({ theme }) => ({
    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("Image/10.png")',
    backgroundRepeat: 'no-repeat, repeat',
    height: '600px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    color: '#fff',
    backgroundAttachment: 'fixed',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '-10px',
        display: 'block',
        height: '890px',
        overflow: 'hidden',
    }
}))

const TextBox = styled(Box)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    fontSize: '25px',
    wordWrap: 'break-word',
    marginTop: '85px',
    marginLeft: '170px',
    letterSpacing: '2px',
    fontWeight: '600',
    [theme.breakpoints.down('lg')]:{
        fontSize: '25px',
        marginTop: '120px',
        marginLeft: '100px',
      },
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        wordWrap: 'break-word',
        padding: '25px',
        marginTop: '10px',
        marginLeft: '30px',
        letterSpacing: '2px',
        fontWeight: '400',
    }
}))


const TextBoxGet = styled(Box)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    width: '40%',
    fontSize: '16px',
    marginTop: '10px',
    wordWrap: 'break-word',
    marginLeft: '170px',
    letterSpacing: '1px',
    lineHeight:'22px',
    fontWeight: '500',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]:{
        width: '80%',
        fontSize: '16px',
        marginLeft: '100px',
        lineHeight:'20px', 
      },
    [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
        marginTop: '-20px',
        wordWrap: 'break-word',
        marginLeft: '55px',
        width: '80%',
        letterSpacing: '1px',
        fontWeight: '400',
        lineHeight:'15px',  
    }
}))






const ImageNew = styled('img')(({ theme }) => ({
    width: '230px',
    height: '460px',
    marginRight: '200px',
    marginTop: '75px',
    [theme.breakpoints.down('lg')]:{
        marginRight: '90px',
        marginTop: '75px',
      },
    [theme.breakpoints.down('sm')]: {
        width: '200px',
        height: '400px',
        marginLeft: '95px',
        marginTop: '30px',
    }
}))


const KartBox = styled(Box)(({ theme }) => ({
    width: '240px',
    height: '70px',
    background: '#F1F3F6',
    marginLeft: '170px',
    marginTop: '25px',
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '100px',
        marginTop: '40px',
      },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '75px',
    }

}))


const TypeTextBox = styled(Box)`
    color: #F15A24;
    margin-left: 17px;
    width: 100%;
    padding: 22px;
    font-size: 16px;
  `;

const AndriodIcon = styled(Box)`
    margin-left: 170px;
    margin-top: -56px;
`;

const ImageSend = styled('img')({
    width:'40px',
    height:'40px',
  })




const TowTruck = () => {
    return (
        <>
            <House />

            <MainBox>
                <Box>
                    <TextBox>Need a tow truck? We can help.</TextBox>
                    <TextBoxGet>Stuck out and about? We can get you a tow truck quick with our on-request and computerized application. When you download the application, basically select the tow truck administration and our framework will consequently associate you with the specialist organization nearest to you. No more value wrangling and outsider organizing while you stand by.</TextBoxGet>

                    <KartBox>
                        <TypeTextBox>Download Now </TypeTextBox>
                        <AndriodIcon>
                        <ImageSend src='Image/PlayStore.png'  style={{ fontSize: '40px', color:'#555555', marginTop:'4px' }} />
                        </AndriodIcon>
                    </KartBox>

                    <KartBox>
                        <TypeTextBox>Download Now </TypeTextBox>
                        <AndriodIcon>
                        <ImageSend src="Image/Andriod.png" alt="" style={{fontSize: '40px', color: '#3DDC84'}}/>
                        </AndriodIcon>
                    </KartBox>

                </Box>
                <ImageNew src="Image/Image-1.png" alt="" />
            </MainBox>


            <FlatSecond />
        </>
    )
}

export default TowTruck
