import React, { useState, useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestoreIcon from '@mui/icons-material/Restore';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import { Box, styled, Typography } from '@mui/material'
import Avatar from 'react-avatar-edit'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const MainBox = styled(Box)(({theme})=>({
    display : 'flex',
    background: '#F3F7F8',
    [theme.breakpoints.down('lg')]:{
        display : 'flex',
        background: '#F3F7F8',
    },
    [theme.breakpoints.down('sm')]:{
        display:'block',
        marginTop:'-55px',
        height: '100%',
    }
}))



const ProfileBox = styled(Box)(({theme})=>({
    width: '325px',
    height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '165px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'35px',
       
    }
}))


const ImageBox = styled(Box)(({theme})=>({
    marginLeft: '105px',
    marginTop: '50px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '75px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'105px',
        paddingTop:'30px',
    }
}))



const NameText = styled(Typography)`
    text-align: center;
    margin: 4px;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`
const NameTextTh = styled(Typography)`
    text-align: center;
    margin: 4px;
    margin-top: -5px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const NumberText = styled(Typography)`
    text-align: center;
    margin-top: 3px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const MainLogoutBox = styled(Box)`
  display: flex;
`

const LogotText = styled(Typography)`
  margin-left: 5px;
  margin-top: 14px;
  color: #F15A24;
`

const MainLogoutBoxSec = styled(Box)(({theme})=>({
    display: 'flex',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]:{
        
    }
}))

const IconBox = styled(Box)(({theme})=> ({
    color: '#F15A24', 
    marginTop: '15px',
    marginLeft: '120px', 
   [theme.breakpoints.down('lg')]:{
     marginLeft: '93px', 
   },
   [theme.breakpoints.down('sm')]:{
        marginLeft:'120px'
   }
}))


const IconBoxSec = styled(Box)(({theme})=>({
    color: '#F15A24', 
    marginTop: '2px', 
    marginLeft: '75px',
    [theme.breakpoints.down('lg')]:{
        marginLeft:'63px',
        marginTop: '0px', 
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'95px'
    }
}))


const LogotTextSec = styled(Typography)(({theme})=>({
    marginLeft: '25px',
    marginTop: '2px',
    color: '#F15A24',
    [theme.breakpoints.down('lg')]:{
        marginTop: '0px',
    }
}))



const ProfileBoxSec = styled(Box)(({theme})=>({
    width: '825px',
    height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '53px',
    [theme.breakpoints.down('lg')]:{
        width: '565px',
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '325px',
        marginLeft: '35px',
        height: '600px',
    }
}))



const ProfileText = styled(Typography)(({theme})=> ({
    textAlign: 'center',
    color: '#F15A24',
    marginTop: '40px',
    marginBottom: '40px',
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: "'Lato', sans-serif",
    [theme.breakpoints.down('lg')]:{
        textAlign: 'center',
        color: '#F15A24',
        marginTop: '40px',
        marginBottom: '40px',
        fontSize: '18px',
        fontWeight: '600',
        fontFamily: "'Lato', sans-serif",
    },
    [theme.breakpoints.down('sm')]:{
        paddingTop:'35px'
    }
}))



const NextBox = styled(Box)(({theme})=>({
    width: '90px',
    height: '30px',
    background: '#F15A24',
    color: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '373px',
    marginTop: '50px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '235px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft: '120px',
        marginTop: '20px',
    }
}))



const NextBoxTh = styled(Box)`
    width: 90px;
    height: 30px;
    background: #F15A24;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 53px;
    margin-top: 30px;
`

const NextType = styled(Typography)`
    padding: 2.5px;
    margin-left: 26px;
  
`

const MainPopBox = styled(Box)`
    margin-top: -35px;
`

const FormBox = styled(Box)(({theme})=>({
    width: '80%',
    marginLeft: '80px',
    [theme.breakpoints.down('lg')]:{
        marginLeft:'60px',
        marginTop:'30px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'33px',
        marginTop:'30px',
    }
}))



// const NameBoxFlex = styled(Box)`
//     display:flex;
//     width: 35%;
//     margin: auto;
// `



const Profile = () => {

    const [dilogs, setDilogs] = useState(false);
    const [imgcro, setImageCrop] = useState(false);
    const [storeImage, setStoreImage] = useState([])
    const [FlatTire, setFlatTire] = useState([]);
   const userid =  localStorage.getItem('userid');
    const FlatQuestion = async () => {
        const response = await fetch(``)
        const data = await response.json();
        setFlatTire(data.data)
    }
    useEffect(() => {
        FlatQuestion();
    }, [])

    const onCrop = (view) => {
        setImageCrop(view)
    }

    const onClose = () => {
        setImageCrop(null)
    }



    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setStoreImage([...storeImage, { imgcro }])
        setDilogs(false)
    };
    const ProfileImageShow = storeImage.map(item => item.imgcro)

    const [userName, setUserName] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        profile_photo:''
    })

       
    const userSetData = async () => {
       const response = await fetch(`https://demo.towbooker.com/api/viewUserbyid?userid=${userid}`)
        const data = await response.json();
        //console.log(data);
       
       setUserName(data.data)
       // localStorage.setItem('username', res.data.message);
        // console.log(data.data) 
         
    }

    useEffect(() => {
        userSetData();
    }, [])

    // update profile function
    

    const [loginInput, setLogin] = useState({
     
    })

    const handleInput = (e) => {
        e.persist();
        setUserName({ ...userName, [e.target.name]: e.target.value })
    }

    async function loginSubmit(e) {
        //alert("hello");
        e.preventDefault();
        //alert(userName.first_name);
        const data = {
            user_id:  userid,
            first_name: userName.first_name,
            last_name: userName.last_name,
            email: userName.email,
            mobile_number: userName.mobile_number,
            profile_photo: userName.profile_photo
        }
        
        await axios.post(`updateProfile`, data).then(res => {
            localStorage.setItem("firstname",userName.first_name);
            localStorage.setItem("lastname",userName.last_name);
            console.log(res);
            if (res.data.status === '200') {
                
                setLogin(res.data);
            }
        })
    }



    return (
        <>

            <MainBox>
                <ProfileBox>
                    <ImageBox>
                        <div className="flex-shrink-0">
                            <img src={ProfileImageShow}
                                alt="Generic placeholder image" className="img-fluid"
                                style={{ width: '110px', borderRadius: '50%' }} />
                        </div>
                    </ImageBox>
            
                        <NameText>{userName.first_name}</NameText>
                        <NameTextTh>{userName.last_name}</NameTextTh>
                   
                    <NumberText>{userName.mobile_number}</NumberText>
                    <NumberText>{userName.email}</NumberText>
                    <MainLogoutBox>
                        <IconBox>
                            <EditIcon style={{   fontSize: '22px',}} />
                        </IconBox>

                        <LogotText>Edit</LogotText>
                    </MainLogoutBox>
                    <hr />
                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <AccountCircleIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
                        <LogotTextSec>Profile</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <AccessTimeIcon style={{fontSize: '22px' }} />
                        </IconBoxSec>
                        <Link to='/booking' style={{ textDecoration: 'none' }} >
                            <LogotTextSec>Bookings</LogotTextSec>
                        </Link>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>

                        <PaymentIcon style={{fontSize: '22px' }} />
                        </IconBoxSec>
                        <LogotTextSec>Payments</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>

                        <RestoreIcon style={{  fontSize: '22px' }} />
                        </IconBoxSec>
                        <LogotTextSec>Transactions</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                        <StarBorderPurple500Icon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
                        <LogotTextSec>Review</LogotTextSec>
                    </MainLogoutBoxSec>

                </ProfileBox>
                <ProfileBoxSec>
                    <ProfileText>Profile Edit</ProfileText>
                    <Box className='profile_img text-center p-4'>
                        <MainPopBox onClick={handleClickOpen}>

                            <img
                                style={{
                                    width: '110px',
                                    height: '110px',
                                    borderRadius: '50%',
                                    objectFit: 'cover'
                                }}
                              
                                src={ProfileImageShow.length ? ProfileImageShow : <AccountCircleIcon/>} alt=""
                                onClick={() => setDilogs(true)}
                              
                            />

                        </MainPopBox>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            visible={dilogs}
                            onHide={() => setDilogs(false)}


                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Upload Image"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    <Avatar width={200} height={200} onClose={onClose} onCrop={onCrop} />
                                </DialogContentText>
                                {/* <NextBoxTh>
                                    <NextType onClick={handleClose}>Disagree</NextType> 
                                </NextBoxTh> */}

                                <NextBoxTh>
                                    <NextType onClick={handleClose}   value={userName.profile_photo} >
                                        Save
                                    </NextType>
                                </NextBoxTh>
                            </DialogContent>
                            <DialogActions>


                            </DialogActions>
                        </Dialog>


                    </Box>


                    <FormBox>
                        <form >
                            <div className="row">
                                <div className="col">
                                    <label >First Name</label>
                                    <input type="text" name='first_name'  onChange={handleInput} className="form-control" value={loginInput.first_name}  placeholder="First name" />
                                </div>
                                <div className="col">
                                    <label >Last Name</label>
                                    <input type="text" name='last_name'  onChange={handleInput} value={loginInput.last_name}   className="form-control" placeholder="Last name" />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group col">
                                    <label for="inputEmail4">Email</label>
                                    <input type="email" name='email'  onChange={handleInput} value={loginInput.email}  className="form-control" id="inputEmail4" placeholder="Email" />
                                </div>
                                <div className="form-group col-md-6">
                                    <label for="inputPassword4">Phone Number</label>
                                    <input type="number" name='mobile_number'  onChange={handleInput} value={loginInput.mobile_number}   className="form-control"  placeholder="Password" />
                                </div>
                            </div>
                        </form>
                    </FormBox>
                    <NextBox>
                        <NextType onClick={loginSubmit}>Save</NextType>
                    </NextBox>
                </ProfileBoxSec>
            </MainBox>
        </>
    )
}

export default Profile
