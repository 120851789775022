
import { styled, Box, Typography } from '@mui/material'
import React from 'react'

import 'react-multi-carousel/lib/styles.css';




const MainClass = styled(Box)(({ theme }) => ({
    marginLeft: '30px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '0px',
    }
}))



const Welcometype = styled(Typography)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    marginTop: '60px',
    fontSize: '25px',
    fontWeight: '600',
    color: '#F15A24',
    textAlign: 'center',
    letterSpacing: '3px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '50px',
    },
    [theme.breakpoints.down('sm')]: {
        letterSpacing: '0px',
        fontSize: '20px',
        marginTop: '25px',
    }
}))



const MachText = styled(Typography)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    color: 'black',
    marginTop: '5px',
    fontSize: '14px',
    fontWeight: '600',
    textAlign: 'center',
    lineHeight: '28px',
    [theme.breakpoints.down('lg')]: {
        color: 'black',
        marginTop: '5px',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'center',
        lineHeight: '28px',
    },
    [theme.breakpoints.down('sm')]: {
        color: 'black',
        padding: '6px',
        marginTop: '0px',
        marginRight: '5px',
        fontSize: '12px',
        fontWeight: '600',
        textAlign: 'center',
        lineHeight: '15px',
    }
}))


const CompanyText = styled(Typography)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    color: 'black',
    fontSize: '14px',
    fontWeight: '600',
    textAlign: 'center',
    lineHeight: '28px',
    [theme.breakpoints.down('lg')]: {
        color: 'black',
        fontSize: '14px',
        fontWeight: '500',
        textAlign: 'center',
        lineHeight: '28px',
    },
    [theme.breakpoints.down('sm')]: {
        color: '#787878;',
        padding: '6px',
        marginTop: '-18px',
        marginRight: '10px',
        fontSize: '13px',
        fontWeight: '600',
        textAlign: 'center',
        lineHeight: '28px',
    }
}))


const SeconMain = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginLeft: '300px',
    marginRight: '300px',
    marginTop: '40px',
    marginBottom: '60px',
    [theme.breakpoints.down('lg')]: {
        display: 'flex',
        marginLeft: '90px',
        marginRight: '0px',
        marginTop: '40px',
        marginBottom: '40px',
    },
    [theme.breakpoints.down('sm')]: {
        display: 'block',
        marginLeft: '300px',
        marginRight: '300px',
        marginTop: '20px',
        marginBottom: '100px',
    }

}))


const DeadText = styled(Typography)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    marginTop: '40px',
    cursor: 'pointer',
    fontSize: '15px',
    lineHeight: '28px',
    fontWeight: '500',
    color: '#303030',
    [theme.breakpoints.down('lg')]: {
        marginTop: '40px',
        cursor: 'pointer',
        fontSize: '15px',
        lineHeight: '28px',
        fontWeight: '500',
        color: '#303030',
    },
    [theme.breakpoints.down('sm')]: {
        cursor: 'pointer',
        fontSize: '15px',
        marginTop: '-10px',
        fontWeight: '600',
        color: '#303030',
        marginLeft: '-170px',
    }
}))

const DeadTextSec = styled(Typography)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    marginTop: '10px',
    cursor: 'pointer',
    fontSize: '15px',
    lineHeight: '28px',
    fontWeight: '500',
    color: '#303030',
    marginLeft: '29px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '10px',
        cursor: 'pointer',
        fontSize: '15px',
        lineHeight: '28px',
        fontWeight: '500',
        color: '#303030',
        marginLeft: '39px',
    },
    [theme.breakpoints.down('sm')]: {
        cursor: 'pointer',
        fontSize: '15px',
        marginTop: '40px',
        fontWeight: '600',
        color: '#303030',
        marginLeft: '-155px',
    }
}))


const MettarText = styled(Typography)(({ theme }) => ({
    fontFamily: "'Lato', sans-serif",
    marginTop: '0px',
    color: '#454545',
    fontSize: '13px',
    lineHeight: '25px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '0px',
        color: '#454545',
        fontSize: '13px',
        lineHeight: '25px',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '8px',
        lineHeight: '20px',
        color: '#454545',
        fontSize: '16px',
        marginLeft: '-175px',
        paddingLeft: '30px',

    }
}))

const MettarTextTh = styled(Typography)(({ theme }) => ({
    marginTop: '10px',
    fontFamily: "'Lato', sans-serif",
    color: '#454545',
    fontSize: '13px',
    lineHeight: '25px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '10px',
        color: '#454545',
        fontSize: '13px',
        lineHeight: '25px',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '8px',
        lineHeight: '20px',
        color: '#454545',
        fontSize: '16px',
        marginLeft: '-190px',
        paddingLeft: '30px',

    }
}))



const MettarTextSec = styled(Typography)(({ theme }) => ({
    marginTop: '2px',
    fontFamily: "'Lato', sans-serif",
    color: '#454545',
    fontSize: '13px',
    lineHeight: '24px',
    padding: '0px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '2px',
        color: '#454545',
        fontSize: '13px',
        lineHeight: '24px',
        padding: '0px',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '8px',
        lineHeight: '20px',
        color: '#454545',
        fontSize: '16px',
        marginLeft: '-235px',
        paddingLeft: '30px',
        textAlign: 'center',

    }
}))


const FirstText = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    marginTop: '0px',
    fontFamily: "'Lato', sans-serif",
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#303030',
    [theme.breakpoints.down('lg')]: {
        cursor: 'pointer',
        marginTop: '0px',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: '500',
        color: '#303030',
    },
    [theme.breakpoints.down('sm')]: {
        cursor: 'pointer',
        marginTop: '80px',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: '600',
        color: '#303030',
        marginLeft: '-190px',
        paddingLeft: '30px',
    }
}))


const NonEmText = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    fontSize: '15px',
    fontFamily: "'Lato', sans-serif",
    lineHeight: '24px',
    fontWeight: '500',
    color: '#303030',
    marginLeft: '29px',
    marginTop: '0px',
    [theme.breakpoints.down('lg')]: {
        cursor: 'pointer',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: '500',
        color: '#303030',
        marginLeft: '39px',
        marginTop: '0px',
    },
    [theme.breakpoints.down('sm')]: {
        cursor: 'pointer',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: '600',
        color: '#303030',
        marginLeft: '-200px',
        paddingLeft: '30px',
        marginTop: '65px',
    }
}))


const TowText = styled(Typography)(({ theme }) => ({
    marginTop: '0px',
    fontSize: '15px',
    fontFamily: "'Lato', sans-serif",
    lineHeight: '15px',
    fontWeight: '500',
    color: '#303030',
    cursor: 'pointer',
    marginLeft: '29px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '0px',
        fontSize: '15px',
        lineHeight: '15px',
        fontWeight: '500',
        color: '#303030',
        cursor: 'pointer',
        marginLeft: '39px',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '75px',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: '600',
        color: '#303030',
        cursor: 'pointer',
        marginLeft: '-180px',
        paddingLeft: '30px',

    }
}))




const TripText = styled(Typography)(({ theme }) => ({
    cursor: 'pointer',
    marginTop: '0px',
    fontFamily: "'Lato', sans-serif",
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: '500',
    color: '#303030',
    marginLeft: '29px',
    [theme.breakpoints.down('lg')]: {
        cursor: 'pointer',
        marginTop: '0px',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: '500',
        color: '#303030',
        marginLeft: '39px',
    },
    [theme.breakpoints.down('sm')]: {
        cursor: 'pointer',
        marginTop: '90px',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: '600',
        color: '#303030',
        marginLeft: '-205px',
        paddingLeft: '30px',

    }
}))


const SeconBox = styled(Box)(({ theme }) => ({
    marginTop: '28px',


}))




const ImageNew = styled('img')(({ theme }) => ({
    width: '230px',
    height: '460px',
    [theme.breakpoints.down('lg')]: {
        width: '230px',
        height: '460px',
    },
    [theme.breakpoints.down('sm')]: {
        width: '100px',
        height: '200px',
        marginLeft: '-185px',
        marginTop: '85px',
        overflow: 'hidden',
    }
}))

const NewTextBox = styled(Box)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.down('lg')]:{
        width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
        width: '160px',
        marginLeft: '-110px',
        fontWeight: '400'
    }
}))

const FirstTextLast = styled(Box)(({ theme }) => ({
    width: '100%',
    cursor: 'pointer',
    marginTop: '0px',
    fontFamily: "'Lato', sans-serif",
    fontSize: '15px',
    lineHeight: '10px',
    fontWeight: '500',
    color: '#303030',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
        cursor: 'pointer',
        marginTop: '0px',
        fontSize: '15px',
        lineHeight: '10px',
        fontWeight: '500',
        color: '#303030',
    },
    [theme.breakpoints.down('sm')]: {
        width: '250px',
        marginLeft: '-220px',
        paddingLeft: '30px',
        cursor: 'pointer',
        marginTop: '70px',
        fontSize: '15px',
        lineHeight: '24px',
        fontWeight: '600',
        color: '#303030',
    }
}))

const NewTextAlign = styled(Typography)(({ theme }) => ({
    marginTop: '0px',
    fontFamily: "'Lato', sans-serif",
    color: '#454545',
    fontSize: '13px',
    lineHeight: '28px',
    [theme.breakpoints.down('lg')]: {
        marginTop: '0px',
        color: '#454545',
        fontSize: '13px',
        lineHeight: '28px',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '8px',
        color: '#454545',
        fontSize: '16px',
        marginLeft: '-200px',
        pading: '10px',
        textAlign: 'center',
    }
}))

const TexConstBox = styled(Box)(({theme})=> ({
    width: '280px',
    height: '90px',
    [theme.breakpoints.down('lg')]: {
        width: '280px',
        height: '90px',
        //border: '1px solid red',
    },
    [theme.breakpoints.down('sm')]: {
        width: '0px',
        height: '0px',
    }
}))

 



const TexConstBoxSec = styled(Box)(({theme})=> ({
    width: '280px',
    height: '90px',
    marginLeft: '30px',
    [theme.breakpoints.down('lg')]: {
        width: '280px',
        height: '90px',
        marginLeft: '40px',
        //border: '1px solid red',
    },
    [theme.breakpoints.down('sm')]: {
        width: '0px',
        height: '0px', 
    }
}))



const ImageBox = styled(Box)(({theme})=> ({
    width: '450px',
    heigh: '600px',
    marginLeft: '40px',
    marginTop: '25px',
    [theme.breakpoints.down('lg')]: {
        width: '250px',
        heigh: '600px',
        marginLeft: '40px',
        marginTop: '5px',
        //border: '1px solid red',
    },
    [theme.breakpoints.down('sm')]: {
        width: '0px',
        heigh: '0px',
        marginLeft: '25px',
        marginTop: '5px',
    }

}))



const SecondHome = () => {
    return (
        <React.Fragment>
            <MainClass>
                <Box>
                    <Welcometype>Welcome to Towbooker</Welcometype>
                    <MachText>Towbooker Services is the first of its kind, on-demand roadside </MachText>
                    <CompanyText>company that automatically dispatches help when you need it!</CompanyText>
                </Box>
                <SeconMain>
                    <Box>
                        <DeadText>DEAD BATTERY</DeadText>
                        <TexConstBox>
                            <NewTextBox>
                                <MettarText>No matter where you are, getting moving again is easy with Roadsiderx….</MettarText>
                            </NewTextBox>
                        </TexConstBox>



                        <FirstText>FLAT TIRE</FirstText>
                        <TexConstBox>
                            <NewTextBox>
                                <MettarText>We make getting roadside easier with our and automated process…</MettarText>
                            </NewTextBox>
                        </TexConstBox>


                        <FirstTextLast>MOBILE MECHANIC</FirstTextLast>
                        <TexConstBox>
                            <NewTextBox>
                                <MettarTextTh>We vet and background check all service providers so you can</MettarTextTh>
                            </NewTextBox>
                        </TexConstBox>




                        <FirstText>LOCKOUTS</FirstText>
                        <TexConstBox>
                            <NewTextBox>
                                <MettarText>We understand the sense of urgency with being locked at Roadsiderx…</MettarText>
                            </NewTextBox>
                        </TexConstBox>

                    </Box>
                    <ImageBox>
                        <ImageNew src="Image/Image-1.png" alt="" />
                    </ImageBox>

                    <SeconBox>
                        <DeadTextSec>OUT OF FUEL</DeadTextSec>
                        <TexConstBoxSec>
                            <NewTextBox>
                                <MettarTextSec>With Towbooker we help get you moving fast and at fair market prices, so you’ll…</MettarTextSec>
                            </NewTextBox>
                        </TexConstBoxSec>


                        <NonEmText>NON-EMERGENCY</NonEmText>
                        <TexConstBoxSec>
                            <NewTextBox>
                                <NewTextAlign>You can use our free service that non-emergency police department…</NewTextAlign>
                            </NewTextBox>
                        </TexConstBoxSec>




                        <TowText>TOW TRUCK</TowText>
                        <TexConstBoxSec>
                            <NewTextBox>
                                <NewTextAlign>We can get you a tow truck fast with our on-demand and automated app….</NewTextAlign>
                            </NewTextBox>
                        </TexConstBoxSec>



                        <TripText>TRIP INSPECTION</TripText>
                        <TexConstBoxSec>
                            <NewTextBox>
                                <MettarText>Order a trip inspection or home, where ever it’s convenient for you and…</MettarText>
                            </NewTextBox>
                        </TexConstBoxSec>


                    </SeconBox>
                </SeconMain>
            </MainClass>

        </React.Fragment>
    )
}

export default SecondHome
