import React,{useEffect} from 'react'
import { styled, Box, Typography} from '@mui/material'
import TowerIcon from '@mui/icons-material/Twitter';
import Feadback from '@mui/icons-material/Facebook';
import MassageIcon from '@mui/icons-material/LinkedIn';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentIcon from '@mui/icons-material/Payment';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { Link, useNavigate } from 'react-router-dom';





const MainBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '50px',
  background: 'black',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    width: '100%',
    height: '60px',
    background: 'black',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '-17px',
    display: 'block',
    height: '160px',
    //overflow: 'hidden',
  }
}))


const NumberBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: '169px',
  fontWeight: '600',
  color: 'white',
  marginTop: '17px',
  fontSize: '13px',
  [theme.breakpoints.down('lg')]: {
    cursor: 'pointer',
    marginLeft: '161px',
    fontWeight: '600',
    color: 'white',
    marginTop: '18px',
    fontSize: '15px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
    marginLeft: '63px',
    marginTop: '-5px',
    fontWeight: '600',
    fontSize: '15px',
    //overflow: 'hidden',

  }
}))


const IconBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  color: 'white',
  [theme.breakpoints.down('lg')]: {
    display: 'flex',
    cursor: 'pointer',
    color: 'white',
    marginLeft: '50px',
    marginTop: '-30px',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    marginLeft: '175px',
    marginTop: '-60px',
    overflow: 'hidden',
  }
}))

const Tiwiter = styled(Box)(({ theme }) => ({
  marginLeft: '25px',
  marginTop: '15px',
  overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '10px',
    marginTop: '47px',

  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '25px',
    marginTop: '15px',
    overflow: 'hidden',
  }
}))

const FaceBook = styled(Box)(({ theme }) => ({
  marginLeft: '65px',
  marginTop: '-30px',
  //overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '30px',
    marginTop: '47px',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '65px',
    marginTop: '-30px',
    //overflow: 'hidden',
  }
}))

const Message = styled(Box)(({ theme }) => ({
  marginLeft: '110px',
  marginTop: '-30px',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '30px',
    marginTop: '47px',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '105px',
    marginTop: '-30px',
    //overflow: 'hidden',
  }
}))



const Text = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: '45.5rem',
  marginTop: '14px',
  letterSpacing: '1px',
  fontSize: '13px',
  fontWeight: '500',
  color: 'white',
  [theme.breakpoints.down('lg')]: {
    cursor: 'pointer',
    marginLeft: '9rem',
    marginTop: '18px',
    letterSpacing: '1px',
    fontSize: '15px',
    fontWeight: '500',
    color: 'white',
  },
  [theme.breakpoints.down('sm')]: {
    color: 'white',
    marginLeft: '100px',
    marginTop: '10px',
    //overflow: 'hidden',
  }
}))


const TextNewBox = styled(Box)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: '600',
  color: 'white',
  marginTop: '15px',
  marginLeft: '15px',
  //overflow: 'hidden',
  [theme.breakpoints.down('lg')]: {
    color: 'white',
    marginTop: '18px',
    marginLeft: '15px',
    //overflow: 'hidden',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '148px',
    marginTop: '0px',
    //overflow: 'hidden',
  }
}))

const TextGetBox = styled(Typography)(({ theme }) => ({
  paddingLeft: '15px',
  color: 'white',
  marginTop: '12px',
  fontWeight: '600',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: '15px',
    color: 'white',
    marginTop: '18px',
    fontWeight: '600',
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '280px',
    marginTop: '-24px',
    height: '40px',
    color: 'black',
    // overflow: 'hidden',
  }
}))

const DropBox = styled(Box)(({theme})=>({
  marginTop: '-7px',
  marginLeft: '-14px',
  [theme.breakpoints.down('lg')]:{
    marginTop: '-7px',
    marginLeft: '-14px',
  },
  [theme.breakpoints.down('sm')]:{
    marginTop: '-7px',
    marginLeft: '-30px',
  }
}))



const MainLogoutBox = styled(Box)`
  display: flex;
`

const LogotText = styled(Typography)`
  margin-left: 10px;
  margin-top: 1px;
`


const Navbar = () => {

  const navigate = useNavigate()
  const [data, setData] = React.useState();


  useEffect(() => {
    setData(localStorage.getItem('firstname'))
  });

  const handleClick = () => {
    localStorage.setItem('firstname', "")
   
  }

  const LongTeram = () => {
    navigate('/')
  }




  return (
    <>
      <MainBox >
        <a style={{textDecoration:'none', marginLeft:'10px'}} href='tel:+919111803533'>
          <NumberBox style={{textDecoration:'none'}} >
            9111-80-3533
          </NumberBox>
        </a>

        <IconBox>
          <Tiwiter>
            <a href='https://twitter.com/i/flow/login' target="_blank" rel="noopener noreferrer">
              <TowerIcon style={{ color: 'white' }} />
            </a>
          </Tiwiter>

          <a href='https://www.facebook.com/' target="_blank" rel="noopener noreferrer">
            <FaceBook>
              <Feadback style={{ color: 'white' }} />
            </FaceBook>
          </a>

          <a href='https://in.indeed.com/' target="_blank" rel="noopener noreferrer">
            <Message>
              <MassageIcon style={{ color: 'white' }} />
            </Message>
          </a>

        </IconBox>
        <Text>
          <Link to='/become' style={{ color: 'white', textDecoration: 'none' }} >Service Provider Signup</Link>
        </Text>

        <TextGetBox> | </TextGetBox>

        <TextNewBox>
          {data ? (<DropBox>
            <div className="dropdown">
              <button className="btn  dropdown-toggle" type="button" id="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ background: 'black', color: 'white', width: '150px', boxShadow: 'none', }} >
                {data}
              </button>
              <div className="dropdown-menu" aria-labelledby="" style={{ zIndex: '10000' }}>

                <Link className="dropdown-item" to="/profile">
                  <MainLogoutBox>
                    <AccountCircleIcon style={{ color: '#F15A24', marginTop: '2px', marginLeft:'-15',fontSize:'22px' }}/>
                    <LogotText>Profile</LogotText>
                  </MainLogoutBox>
                </Link>

                
                <Link className="dropdown-item" to="">
                  <MainLogoutBox>
                    <AccessTimeIcon style={{ color: '#F15A24', marginTop: '2px', marginLeft:'-15',fontSize:'22px' }}/>
                        <Link to='/booking' style={{ textDecoration: 'none', color: 'black', }} >
                            <LogotText>Bookings</LogotText>
                        </Link>
                  </MainLogoutBox>
                
                </Link>
                <Link className="dropdown-item" to="">
                  <MainLogoutBox>
                    <PaymentIcon style={{ color: '#F15A24', marginTop: '2px', marginLeft:'-15',fontSize:'22px' }}/>
                    <LogotText>Payment</LogotText>
                  </MainLogoutBox>
                </Link>

                <Link className="dropdown-item" to="#">
                  <MainLogoutBox>
                    <AddLocationAltIcon style={{ color: '#F15A24', marginTop: '2px', marginLeft:'-15',fontSize:'22px' }}/>
                    <LogotText>Address</LogotText>
                  </MainLogoutBox>
                </Link>

                <Link className='dropdown-item' onClick={() => handleClick(window.location.reload(true))}>
                  <MainLogoutBox>
                    <PowerSettingsNewIcon style={{ color: '#F15A24', marginTop: '2px', marginLeft:'-15',fontSize:'22px' }} />
                    <LogotText onClick={LongTeram}>Logout</LogotText>
                  </MainLogoutBox>
                </Link>
            
              </div>
            </div>
          </DropBox>) : (
            <Link to='/login' style={{ color: 'white', textDecoration: 'none' }} ><Typography>User Signup</Typography>
            </Link>
          )}
        </TextNewBox>
      </MainBox>

    </>
  )
}

export default Navbar
