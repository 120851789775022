import React, {useState, useEffect} from 'react'
import EditIcon from '@mui/icons-material/Edit';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestoreIcon from '@mui/icons-material/Restore';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import { Link, useNavigate } from 'react-router-dom';
import { Box, styled, Typography } from '@mui/material'
import MyComponent from './MyComponent';
import axios from 'axios';
import swal from 'sweetalert';


const MainBox = styled(Box)(({theme})=>({
    display : 'flex',
    background: '#F3F7F8',
    [theme.breakpoints.down('lg')]:{
        display : 'flex',
        background: '#F3F7F8',
    },
    [theme.breakpoints.down('sm')]:{
        display:'block',
        marginTop:'-55px',
        height: '100%',
    }
}))

const ProfileBox = styled(Box)(({theme})=>({
    width: '325px',
    height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '165px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'33px',       
    }
}))


const ImageBox = styled(Box)(({theme})=>({
    marginLeft: '105px',
    marginTop: '50px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '75px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'105px',
        paddingTop:'30px',
    }
}))

const NameText = styled(Typography)`
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const NumberText = styled(Typography)`
    text-align: center;
    margin-top: 3px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const MainLogoutBox = styled(Box)`
  display: flex;
`

const LogotText = styled(Typography)`
  margin-left: 5px;
  margin-top: 14px;
  color: #F15A24;
`

const MainLogoutBoxSec = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]: {

    }
}))

const IconBoxFr= styled(Box)(({ theme }) => ({
    color: '#F15A24',
    marginTop: '15px',
    marginLeft: '120px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '125px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'120px'
    }
}))

const IconBoxSec = styled(Box)(({ theme }) => ({
    color: '#F15A24',
    marginTop: '2px',
    marginLeft: '75px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '93px',
        marginTop: '0px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'95px'
    }
}))


const LogotTextSec = styled(Typography)(({ theme }) => ({
    marginLeft: '25px',
    marginTop: '2px',
    color: '#F15A24',
    [theme.breakpoints.down('lg')]: {
        marginTop: '0px',
    }
}))


const ProfileBoxSec = styled(Box)(({theme})=>({
    width: '825px',
    //height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '53px',
    [theme.breakpoints.down('lg')]:{
        width: '565px',
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '325px',
        marginLeft: '35px',
        height: '680px',
        // boxShadow: '0 2px 4px 0 rgb(0 0 0 / 0%)',
        // background: '#F3F7F8',
    }
}))


const AddVehicle = styled(Typography)(({theme})=>({
    fontSize: '18px',
    fontWeight: '600',
    fontFamily: "'Lato', sans-serif",
    textAlign: 'center',
    marginTop: '35px',
    [theme.breakpoints.down('lg')]:{
        fontSize: '18px',
        fontWeight: '600',
        fontFamily: "'Lato', sans-serif",
        textAlign: 'center',
        marginTop: '35px',
    },
    [theme.breakpoints.down('sm')]:{
        paddingTop:'55px'
    }
}))

const SelectBoxSec = styled(Box)(({theme})=> ({
    width: '370px',
    height: '280px',
    background: '#FFFFFF',
    boxShadow: "0 2px 4px 4px rgb(0 0 0 / 10%)",
    borderRadius: '9px',
    marginLeft: '225px',
    marginTop: '40px',
    [theme.breakpoints.down('lg')]:{
        marginLeft:'103px'
    },
    [theme.breakpoints.down('sm')]:{
        width: '270px',
        height: '280px',
        marginLeft:'26px'
    }
}))


const SelectBoxTh = styled(Box)(({theme})=> ({
    width: '370px',
    height: '80px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 4px rgb(0 0 0 / 10%)',
    borderRadius: '9px',
    marginLeft: '225px',
    marginTop: '40px',
    [theme.breakpoints.down('lg')]:{
        marginLeft:'103px'
    },
    [theme.breakpoints.down('sm')]:{
        width: '270px',
        height: '80px',
        marginLeft:'26px'
    }
}))



const MapBox = styled(Box)(({theme})=> ({
    width: '280px',
    height: '400px',
    padding: '5px',
    marginLeft: '45px',
    [theme.breakpoints.down('lg')]:{
        width: '280px',
        height: '400px',
        padding: '5px',
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '270px',
        height: '280px',
        marginLeft:'0px'
    }
}))



const SearchText = styled(Typography)`
font-size: 13px;
text-align: center;
padding: 4px;
font-weight:600;
font-family: 'Lato', sans-serif;
`

const NextBox = styled(Box)(({theme})=>({
    width: '180px',
    height: '30px',
    background: '#F15A24',
    color: 'white',
    borderRadius: '9px',
    cursor: 'pointer',
    marginLeft: '330px',
    marginTop: '37px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '205px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'75px',
        marginTop:'55px',
    }
}))



const NextType = styled(Typography)`
    padding: 1.5px;
    margin-left: 27px;
    font-family: 'Lato', sans-serif;
`

const ThirdBox = styled(Box)`
    display: flex;
`

const ImageBoxLc = styled(Box)(({theme})=> ({
    padding: '15px',
    marginLeft: '25px',
    [theme.breakpoints.down('lg')]:{
        padding: '15px',
        marginLeft: '25px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'0',
        marginTop:'10px'
    }
}))


const ImageBoxSec = styled('img')(({theme})=> ({
    width: '50px',
    height: '50px',
    [theme.breakpoints.down('lg')]:{
        width: '50px',
        height: '50px',
    },
    [theme.breakpoints.down('sm')]:{
        width:'30px',
        height:'30px'
    }
}))


const AirText = styled(Typography)(({theme})=> ({
    fontSize: '13px',
    padding: '22px',
    marginLeft: '-15px',
    fontWeight: '600',
    fontFamily: "'Lato', sans-serif",
    [theme.breakpoints.down('lg')]:{
        fontSize: '13px',
        padding: '22px',
        marginLeft: '-15px',
        fontWeight: '600',
        fontFamily: "'Lato', sans-serif",
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft: '-15px',
        paddingTop:'25px',
        fontSize:'10px',
    }
}))



const StartText = styled(Typography)(({theme})=> ({
    fontSize: '13px',
    padding: '5px',
    marginLeft: '2px',
    marginTop: '-30px',
    fontWeight: '500',
    fontFamily: "'Lato', sans-serif'",
    [theme.breakpoints.down('lg')]:{
        fontSize: '13px',
        padding: '5px',
        marginLeft: '2px',
        marginTop: '-30px',
        fontWeight: '500',
        fontFamily: "'Lato', sans-serif'", 
    },
    [theme.breakpoints.down('sm')]:{
        paddingLeft:'100px',
        fontSize:'10px',
    }
}))



const Daulor = styled(Typography)(({theme})=> ({
    fontSize: '15px',
    padding: '30px',
    marginLeft: '70px',
    fontWeight: '600',
    fontFamily: "'Lato', sans-serif",
    [theme.breakpoints.down('lg')]:{
        fontSize: '15px',
        padding: '30px',
        marginLeft: '70px',
        fontWeight: '600',
        fontFamily: "'Lato', sans-serif", 
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'10px'
    }
}))


const NameTextTh = styled(Typography)`
    text-align: center;
    margin: 4px;
    margin-top: -5px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`



const ComfirmBookin = () => {
    const Navigate = useNavigate();
    const search = window.location.search;
    const params = new URLSearchParams(search)
    let vtype = params.get("vtype");
    let servtype = params.get("servtype");
    let subsurvice = params.get("subsurvice");
    let subservname = params.get("subservname");
    let image = params.get("image");
    let price = params.get("price");
    const userid =  localStorage.getItem('userid');
    const [storeImage, setStoreImage] = useState([])

    const [userName, setUserName] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        profile_photo:''
    })

    const ProfileImageShow = storeImage.map(item => item.imgcro)
       
    const userSetData = async () => {
       const response = await fetch(`https://demo.towbooker.com/api/viewUserbyid?userid=${userid}`)
        const data = await response.json();
        //console.log(data);
       
       setUserName(data.data)
       // localStorage.setItem('username', res.data.message);
        // console.log(data.data) 
         
    }

    useEffect(() => {
        userSetData();
    }, [])

    const data = {
        user_id:'',
        service_id:'',
        sub_service_id:'',
        s_address:'',
        s_latitude:'',
        s_longitude:'',
        answerdata:'',
        vehicle_type:'',
        schedule_start:'',
        schedule_end:'',
        person:'',
        answerdata:'',
      }

    const[confirmData, setConfirmData] = useState([])
    

    async function comfirmBooking (){
        swal("Sucess Booking");
   
        await axios.post('serviceBook', data).then(res=>{
            if(res.data.status === '200'){
                setConfirmData(res.data)
            }
        })
    }
    

    return (
        <>
            <MainBox>
            <ProfileBox>
                    <ImageBox>
                        <div className="flex-shrink-0">
                            <img src={ProfileImageShow}
                                alt="Generic placeholder image" className="img-fluid"
                                style={{ width: '110px', borderRadius: '50%' }} />
                        </div>
                    </ImageBox>
            
                        <NameText>{userName.first_name}</NameText>
                        <NameTextTh>{userName.last_name}</NameTextTh>
                   
                    <NumberText>{userName.mobile_number}</NumberText>
                    <NumberText>{userName.email}</NumberText>
                    <MainLogoutBox>
                        <IconBoxFr>
                            <EditIcon style={{  fontSize: '22px' }} />
                        </IconBoxFr>
                        
                        <LogotText>Edit</LogotText>
                    </MainLogoutBox>
                    <hr />
                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <AccountCircleIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
         
                        <LogotTextSec>Profile</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <AccessTimeIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
                    
                        <Link to='/booking' style={{ textDecoration: 'none' }} >
                            <LogotTextSec>Bookings</LogotTextSec>
                        </Link>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <PaymentIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
                   
                        <LogotTextSec>Payments</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <RestoreIcon style={{fontSize: '22px' }} />
                        </IconBoxSec>
               
                        <LogotTextSec>Transactions</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <StarBorderPurple500Icon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
                
                        <LogotTextSec>Review</LogotTextSec>
                    </MainLogoutBoxSec>

                </ProfileBox>

                <ProfileBoxSec>
                    <AddVehicle> Confirm Booking</AddVehicle>

                    <SelectBoxSec>
                        <SearchText>Search Your Location</SearchText>
                        <MapBox>

                            <div className="form-group ">
                                <input type="text" id="searchTextField" className="form-control register-form_location-holder" name="Address" />
                                <div id="map" className="register-formmap register-form_map--user" style={{ width: '40%', height: '300px' }}></div>
                                <input type="hidden" name="user_latitude"  value="" className="register-form__latitude-holder" />
                                <input type="hidden" name="user_longitude" value="" className="register-form__longitude-holder" id='map'></input>
                                
                            </div>
                            <MyComponent/>
                        </MapBox>

                    </SelectBoxSec>
                    <SelectBoxTh>
                        <ThirdBox>
                            <ImageBoxLc>
                                <ImageBoxSec src={image} alt="" />
                            </ImageBoxLc>
                            <Box>
                                <AirText>{subservname}</AirText>
                                <StartText>start at</StartText>
                            
                            </Box>
                            <Daulor>${price}</Daulor>
                        </ThirdBox>
                    </SelectBoxTh>
                    <NextBox>
                        <Link  to='/' style={{ textDecoration: 'none' }} >
                            <NextType onClick={comfirmBooking} style={{ color: 'white', }}>Confirm Booking</NextType>
                        </Link>

                    </NextBox>
                </ProfileBoxSec>

            </MainBox>

        </>
    )
}

export default ComfirmBookin
