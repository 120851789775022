import React from 'react'
import EditIcon from '@mui/icons-material/Edit';
import PaymentIcon from '@mui/icons-material/Payment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import RestoreIcon from '@mui/icons-material/Restore';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, Button, styled, Typography } from '@mui/material'
import { useState , useEffect} from 'react';
import { Link } from 'react-router-dom';





const MainBox = styled(Box)(({theme})=>({
    display : 'flex',
    background: '#F3F7F8',
    [theme.breakpoints.down('lg')]:{
        display : 'flex',
        background: '#F3F7F8',
    },
    [theme.breakpoints.down('sm')]:{
        display:'block',
        marginTop:'-55px',
        height: '100%',
    }
}))

const ProfileBox = styled(Box)(({theme})=>({
    width: '325px',
    height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '165px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'35px',       
    }
}))


const ImageBox = styled(Box)(({theme})=>({
    marginLeft: '105px',
    marginTop: '50px',
    [theme.breakpoints.down('lg')]:{
        marginLeft: '75px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'105px',
        paddingTop:'30px',
    }
}))


const NameText = styled(Typography)`
    text-align: center;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const NumberText = styled(Typography)`
    text-align: center;
    margin-top: 3px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`

const MainLogoutBox = styled(Box)`
  display: flex;
`

const LogotText = styled(Typography)`
  margin-left: 5px;
  margin-top: 14px;
  color: #F15A24;
`

const MainLogoutBoxSec = styled(Box)(({ theme }) => ({
    display: 'flex',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]: {

    }
}))

const IconBoxFr= styled(Box)(({ theme }) => ({
    color: '#F15A24',
    marginTop: '15px',
    marginLeft: '120px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '125px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'120px'
    }

}))

const IconBoxSec = styled(Box)(({ theme }) => ({
    color: '#F15A24',
    marginTop: '2px',
    marginLeft: '75px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '93px',
        marginTop: '0px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'95px'
    }
}))


const LogotTextSec = styled(Typography)(({ theme }) => ({
    marginLeft: '25px',
    marginTop: '2px',
    color: '#F15A24',
    [theme.breakpoints.down('lg')]: {
        marginTop: '0px',
    }
}))

const ProfileBoxSec = styled(Box)(({theme})=>({
    width: '825px',
    //height: '600px',
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
    marginTop: '60px',
    marginBottom: '60px',
    marginLeft: '53px',
    [theme.breakpoints.down('lg')]:{
        width: '565px',
        marginLeft: '45px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '325px',
        marginLeft: '35px',
        height: '100%',
        boxShadow: '0 2px 4px 0 rgb(0 0 0 / 0%)',
        background: '#F3F7F8',
    }
}))


const SelectBox = styled(Box)(({theme})=> ({
    width: '370px',
    height: '80px',
    background: '#FFFFFF',
    boxShadow: "0 2px 4px 4px rgb(0 0 0 / 10%)",
    borderRadius: '9px',
    marginLeft: '225px',
    marginTop: '30px',
    [theme.breakpoints.down('lg')]:{
        marginLeft:'103px'
    },
    [theme.breakpoints.down('sm')]:{
        width: '290px',
        height:'90px',
        marginLeft:'18px',
        marginTop:'58px',
   
    }
}))



const SelectBoxSec = styled(Box)(({theme})=> ({
    width: '370px',
    height: '80px',
    background: '#FFFFFF',
    boxShadow: "0 2px 4px 4px rgb(0 0 0 / 10%)",
    borderRadius: '9px',
    marginLeft: '225px',
    marginTop: '30px',
    [theme.breakpoints.down('lg')]:{
        marginLeft:'103px',
    },
    [theme.breakpoints.down('sm')]:{
        width: '290px',
        height:'90px',
        marginLeft:'18px',
   
    }
}))



const SelectText = styled(Typography)`
    font-size: 13px;
    text-align: center;
    padding: 15px;
    font-weight:600;
    font-family: 'Lato', sans-serif;
`

const SelectTextSec = styled(Typography)`
    font-size: 12px;
    text-align: center;
    padding: -15px;
    font-weight:500;
    font-family: 'Lato', sans-serif;
`

const StartTime = styled(Typography)`
    font-size: 10px;
    font-weight: 600;
    font-family: 'Lato', sans-serif;
`

const TimeBox = styled(Box)(({theme})=>({
    width: '70%',
    marginLeft: '57px',
    [theme.breakpoints.down('lg')]:{
        width: '70%',
        marginLeft: '57px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'42px'
    }
}))


const NextBox = styled(Box)(({ theme }) => ({
    width: '90px',
    height: '30px',
    background: '#F15A24',
    color: 'white',
    borderRadius: '4px',
    cursor: 'pointer',
    marginLeft: '373px',
    marginTop: '10px',
    [theme.breakpoints.down('lg')]: {
        marginLeft: '235px',
        marginTop: '25px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'120px',
        marginTop:'20px',
        marginBottom:'80px',
    }
}))

const NextType = styled(Typography)`
    padding: 3px;
    margin-left: 23px;
`

const NamaBox = styled(Box)(({theme})=>({
    display: 'flex',
    marginLeft: '85px',
    marginTop: '15px',
    [theme.breakpoints.down('lg')]:{
        display: 'flex',
        marginLeft: '85px',
        marginTop: '15px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'40px',
        marginTop:'20px'
    }
}))


const BoxAdd = styled(Box)`
    display: flex;
`

const SelectBoxTh = styled(Typography)(({theme})=> ({
    fontSize: '13px',
    fontWeight: '500',
    fontFamily: "'Lato', sans-serif",
    marginTop: '29px',
    marginLeft: '59px',
    [theme.breakpoints.down('lg')]:{
        fontSize: '13px',
        fontWeight: '500',
        fontFamily: "'Lato', sans-serif",
        marginTop: '29px',
        marginLeft: '59px',
    },
    [theme.breakpoints.down('sm')]:{
        marginLeft:'35px',
        marginTop:'35px'
    }
}))




const IconBox = styled(Box)`
    width: 20px;
    height: 20px;
    border: 1px solid black;
    border-radius: 50%;
    padding: -1px;
    cursor: pointer;
    margin-top: 14px;
   
`

const NumberTextOk = styled(Typography)`
    font-size: 13px;
    font-weight: 500;
    margin: 15px;
`

const NameTextTh = styled(Typography)`
    text-align: center;
    margin: 4px;
    margin-top: -5px;
    font-size: 16px;
    font-weight: 500;
    color: #F15A24;
`


const SeduleBooking = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search)
    let vtype = params.get("vtype");
    let servtype = params.get("servtype");
    let subsurvice = params.get("subsurvice");
    let subservname = params.get("subservname");
    let image = params.get("image");
    let price = params.get("price");
    const userid =  localStorage.getItem('userid');
    const [storeImage, setStoreImage] = useState([])

    const [Number, setNumber] = useState(1)

    const Increment = () => {
        setNumber(Number + 1);
    }

    const Decrement = () => {
        if (Number > 1) {
            setNumber(Number - 1);
        }
        else {
            setNumber(1)
        }

    }

    const [userName, setUserName] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        profile_photo:''
    })

    const ProfileImageShow = storeImage.map(item => item.imgcro)
       
    const userSetData = async () => {
       const response = await fetch(`https://demo.towbooker.com/api/viewUserbyid?userid=${userid}`)
        const data = await response.json();
        //console.log(data);
       
       setUserName(data.data)
       // localStorage.setItem('username', res.data.message);
        // console.log(data.data) 
         
    }

    useEffect(() => {
        userSetData();
    }, [])




    return (
        <>
            <MainBox>
            <ProfileBox>
                    <ImageBox>
                        <div className="flex-shrink-0">
                            <img src={ProfileImageShow}
                                alt="Generic placeholder image" className="img-fluid"
                                style={{ width: '110px', borderRadius: '50%' }} />
                        </div>
                    </ImageBox>
            
                        <NameText>{userName.first_name}</NameText>
                        <NameTextTh>{userName.last_name}</NameTextTh>
                   
                    <NumberText>{userName.mobile_number}</NumberText>
                    <NumberText>{userName.email}</NumberText>
                    <MainLogoutBox>
                        <IconBoxFr>
                            <EditIcon style={{fontSize: '22px' }} />
                        </IconBoxFr>
                
                        <LogotText>Edit</LogotText>
                    </MainLogoutBox>
                    <hr />
                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <AccountCircleIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
             
                        <LogotTextSec>Profile</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <AccessTimeIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
                       
                        <Link to='/booking' style={{ textDecoration: 'none' }} >
                            <LogotTextSec>Bookings</LogotTextSec>
                        </Link>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <PaymentIcon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
                
                        <LogotTextSec>Payments</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <RestoreIcon style={{  fontSize: '22px' }} />   
                        </IconBoxSec>
                    
                        <LogotTextSec>Transactions</LogotTextSec>
                    </MainLogoutBoxSec>

                    <MainLogoutBoxSec>
                        <IconBoxSec>
                            <StarBorderPurple500Icon style={{ fontSize: '22px' }} />
                        </IconBoxSec>
             
                        <LogotTextSec>Review</LogotTextSec>
                    </MainLogoutBoxSec>

                </ProfileBox>

                <ProfileBoxSec>
                    <SelectBox>
                        <SelectText>Select between the time you need service by</SelectText>
                        <SelectTextSec>Request Towbooker Assistance.</SelectTextSec>
                    </SelectBox>

                    <SelectBoxSec>
                        <div className="form-group" style={{ textAlign: 'center' }}>
                            <label for="exampleInputTime">
                                <StartTime >Start Time</StartTime>
                            </label>
                            <TimeBox>
                                <input type="time" className="form-control" id="exampleInputTime" />
                            </TimeBox>
                        </div>
                    </SelectBoxSec>

                    <SelectBoxSec>
                        <div className="form-group" style={{ textAlign: 'center' }}>
                            <label for="exampleInputTime">
                                <StartTime >End Time</StartTime>
                            </label>
                            <TimeBox>
                                <input type="time" className="form-control" id="exampleInputTime" />
                            </TimeBox>

                        </div>
                    </SelectBoxSec>

                    <SelectBoxSec>
                        <div className="form-group" style={{ textAlign: 'center' }}>
                            <label for="exampleInputDate">
                                <StartTime>Select Date</StartTime>
                            </label>
                            <TimeBox>
                                <input type="date" className="form-control" id="exampleInputDate" />
                            </TimeBox>
                        </div>
                    </SelectBoxSec>
                    <div>
                        <SelectBoxSec>
                            <BoxAdd>
                                <SelectBoxTh>No. of Persons</SelectBoxTh>
                                <NamaBox>
                                <Box onClick={Decrement}>
                                    <IconBox>
                                        <RemoveIcon style={{fontSize: '16px', marginLeft:'1px'}} />
                                    </IconBox>
                                </Box>
                                <NumberTextOk>{Number}</NumberTextOk>
                                <Box onClick={Increment}>
                                    <IconBox>
                                        <AddIcon style={{fontSize: '16px', marginLeft:'1px'}}/>
                                    </IconBox>
                                </Box>
                                </NamaBox>
                             
                            </BoxAdd>
                        </SelectBoxSec>
                    </div>
                    <NextBox>
                         <Link to={`/vehicleinfo?vtype=${vtype}&&servtype=${servtype}&&subsurvice=${subsurvice}&&subservname=${subservname}&&image=${image}&&price=${price}`} style={{textDecoration: 'none'}}>
                            <NextType style={{color:'white', }}>Next</NextType>
                        </Link>

                    </NextBox>
                </ProfileBoxSec>

            </MainBox>
        </>
    )
}

export default SeduleBooking
