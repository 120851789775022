import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState, useEffect} from 'react'

import axios from 'axios'

import House from '../Header/House';

import swal from 'sweetalert'




const FromBox = styled(Box)(({ theme }) => ({
  padding: '60px',
  width: '50%',
  marginLeft: '400px',
  marginTop: '-5px',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
    marginLeft: '0px',
    marginTop: '-20px',
  }
}))

const TextWithBox = styled(Box)`
    margin-top: 40px;
`

const TextUser = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  padding: '15px',
  color: 'grey',
  fontSize: '22px',
  fontWeight: '600',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '5px',
    marginTop: '-20',
  }
}))

const TextUserSec = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: '-15px',
  color: 'grey',
  fontSize: '18px',
  fontWeight: '600',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '2px',
    marginTop: '-20',
  }
}))


const TextUserSecFrist = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginTop: '-2px',
  color: 'grey',
  fontSize: '15px',
  fontWeight: '600',
  [theme.breakpoints.down('lg')]: {
    marginLeft: '5px',
    marginTop: '-20',
    marginBottom: '10px',
  }
}))

const RequiredFild = styled(Typography)`
    color: red;
    font-size: 12px;
    font-weight: 600;
    margin-top: -20px;
`

const Login = () => {
  const [formErrors, setformErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate()
  const [loginInput, setLogin] = useState({
    email: '',
    password: '',
  })
  const handleInput = (e) => {
    e.persist();
    setLogin({ ...loginInput, [e.target.name]: e.target.value })

  }
  useEffect(() => {
    console.log(formErrors)
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors])
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required!';
    }
    if (!values.password) {
      errors.password = 'Password is required!';
    }
    return errors;
  }
  async function loginSubmit(e) {
    e.preventDefault();
    setformErrors(validate(loginInput));
    setIsSubmit(true);

    const data = {
      email: loginInput.email,
      password: loginInput.password,
    }

    await axios.post(`email-login`, data).then(res => {
      if (res.data.status === '200') {
        setLogin(res.data);

       // console.log("mydata",res.data.message);
        
        localStorage.setItem('firstname', res.data.message.first_name);
        localStorage.setItem('lastname', res.data.message.last_name);
        localStorage.setItem('userid', res.data.message.id);
        
   
      swal('Login Success')
 
        navigate('/')
      }
      else {
        swal("Invalid Email OR Password")
      }
    })
  }


  return (
    <>
      <House />
      <TextWithBox>
        <TextUser>User signup</TextUser>
        <TextUserSec>Want to book your service</TextUserSec>
        <TextUserSecFrist>Please sign</TextUserSecFrist>
      </TextWithBox>
      <FromBox>


        <div className="form-outline mb-4">
          <label className="form-label" >Email address</label>
          <input type="email" name='email' onChange={handleInput} value={loginInput.email} id="form2Example1" className="form-control" />
        </div>
        <RequiredFild>{formErrors.email}</RequiredFild>

        <div className="form-outline mb-4">
          <label className="form-label" >Password</label>
          <input type="password" name='password' onChange={handleInput} value={loginInput.password} id="form2Example2" className="form-control" />
        </div>

        <RequiredFild>{formErrors.password}</RequiredFild>

        <div className="row mb-4">
          <div className="col d-flex justify-content-center">

            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" id="form2Example31" checked />
              <label className="form-check-label" for="form2Example31"> Remember me </label>
            </div>
          </div>

          <div className="col">

            <Link to="/forget">Forgot password?</Link>
          </div>
        </div>


        <button  onClick={loginSubmit} className="btn btn-secondary btn-block mb-4">Login</button>


        <div className="text-center">
          <p>Don't have Profile ? <Link to='/register'>Register with Us</Link></p>
          <p>or sign up with:</p>
          <button type="button" className="btn btn-link btn-floating mx-1">
            <i className="fab fa-facebook-f"></i>
          </button>

          <button type="button" className="btn btn-link btn-floating mx-1">
            <i className="fab fa-google"></i>
          </button>

          <button type="button" className="btn btn-link btn-floating mx-1">
            <i className="fab fa-twitter"></i>
          </button>

          <button type="button" className="btn btn-link btn-floating mx-1">
            <i className="fab fa-github"></i>
          </button>
        </div>
      </FromBox>
    </>
  )
}

export default Login

